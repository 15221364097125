<template>
  <div class="parallel-setting">
    <div class="parallel-setting__text">Calls</div>
    <div ref="parallelSettingDropdown" class="parallel-setting__dropdown">
      <div
        @click="toggleDropdown"
        class="dropdown__button"
        :class="{
          'dropdown__button--enable': !disable,
          'dropdown__button--disable': disable
        }"
      >
        <div class="dropdown__buttonText">{{ sessionStore.callInParallel }}</div>
        <ChevronDownIcon class="dropdown__button-svg" v-if="!isOpen" color="#D0D5DD" size="12" />
        <ChevronUpIcon class="dropdown__button-svg" v-else color="#D0D5DD" size="12" />
      </div>
      <div v-if="isOpen" class="dropdown--open" @click.stop="">
        <div class="dropdown__main">
          <div
            class="dropdown__item"
            v-for="setting in settingList"
            :key="setting"
            @click="selectSetting(setting)"
          >
            <div
              class="dropdown__item-group"
              :class="{
                'dropdown__item--selected': setting === sessionStore.callInParallel,
                'dropdown__item--unselected': setting !== sessionStore.callInParallel
              }"
            >
              <div v-if="setting === sessionStore.callInParallel" class="checkmark-icon">
                <CheckmarkIcon size="21" />
              </div>
              <div class="dropdown__item-text">
                {{ setting }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core'
import CheckmarkIcon from './icons/CheckmarkIcon.vue'
import ChevronDownIcon from './icons/ChevronDownIcon.vue'
import ChevronUpIcon from './icons/ChevronUpIcon.vue'
import { ref } from 'vue'
import { usePhoneStore } from '@/stores/phone'
import { useSessionStore } from '@/stores/sessionStore'

const phoneStore = usePhoneStore()
const sessionStore = useSessionStore()

const props = defineProps({
  disable: {
    required: true,
    type: Boolean
  }
})

const settingList = ref([1, 2, 3, 4, 5])
// const settingList = ref([1, 2])

const isOpen = ref(false)

function toggleDropdown() {
  if (props.disable) {
    return
  }
  isOpen.value = !isOpen.value
}

const parallelSettingDropdown = ref(null)

onClickOutside(parallelSettingDropdown, () => {
  closeDropdown()
})

function closeDropdown() {
  isOpen.value = false
}

function selectSetting(setting) {
  sessionStore.callInParallel = setting
  isOpen.value = false
  sessionStore.updateSessionSettings({ parallelCalls: setting })
}
</script>

<style scoped lang="scss">
.parallel-setting {
  display: flex;
  user-select: none;
  flex-direction: column;

  .parallel-setting__text {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 4px;
  }
}
.parallel-setting__dropdown {
  position: relative;
  display: inline-block;
}

.parallel-setting__dropdown .dropdown__button {
  padding: 10px 8px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  font-weight: 500;
  width: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.4s ease;
}

.parallel-setting__dropdown .dropdown__button--enable {
  color: #344054;
  background: #fff;
}
.parallel-setting__dropdown .dropdown__button--enable:hover {
  border: 1px solid #c2c2c2;
  cursor: pointer;
}
.parallel-setting__dropdown .dropdown__button--disable {
  color: #b8b8b8;
  background: #f6f6f6;
  user-select: none;
}

.dropdown__buttonText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  line-height: 14px;
}

.dropdown--open {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #344054;
  font-size: 14px;
  display: inline-block;
  font-weight: 500;
  width: 60px;
  overflow: visible;
  background: #fff;
  z-index: 2;
}

.dropdown__main {
  padding: 4px;
}

.dropdown__item-group {
  display: flex;
  align-items: center;
}
.dropdown__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.05s ease;
  padding: 4px 4px 4px 0;
}
.dropdown__item-text {
  max-width: 155px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown__item:hover {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.07);
  opacity: 1;
  cursor: pointer;
}
.checkmark-icon {
  display: flex;
}
.dropdown__item--selected {
  padding-left: 0;
  font-weight: 600;
}
.dropdown__item--unselected {
  padding-left: 21px;
}

.dropdown__button-svg {
  pointer-events: none;
}
</style>
