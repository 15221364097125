<template>
  <div class="analytics-page">
    <TheSessionTitle :items="items" />
    <router-view></router-view>
  </div>
</template>

<script setup>
import TheSessionTitle from './components/TheSessionTitle.vue'
import { computed, onMounted, watch } from 'vue'
import router from './router'
import { useAnalyticsStore } from './stores/analyticsStore'
import { useRoute } from 'vue-router'

const analyticsStore = useAnalyticsStore()

const route = useRoute()

onMounted(() => {
  analyticsStore.setLogType(route.params.logType)
})

watch(
  () => [route.params.userId, route.params.logType],
  () => {
    analyticsStore.setLogType(route.params.logType)
    if (!route.params.userId) {
      analyticsStore.setUserName('')
    }
  }
)

const items = computed(() => {
  if (analyticsStore.userName) {
    return [
      { label: 'Analytics', command: redirectToAnalyticsList },
      { label: `${analyticsStore.userName} - ${analyticsStore.getLogTypeLabel}` }
    ]
  }
  return [{ label: 'Analytics', command: redirectToAnalyticsList }]
})

function redirectToAnalyticsList() {
  router.push('/analytics')
}
</script>

<style lang="scss">
.analytics-page {
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1 1 auto;
  width: 100%;

  .page-title {
    font-size: 20px;
    font-weight: 600;
    color: #344054;
    margin-bottom: 24px;
  }
}
</style>
