<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6667 10.9375L16.6667 5.49993C16.6667 3.84306 15.3235 2.49991 13.6667 2.49993L8.22919 2.49999M11.6667 17.5L6.04169 17.5C5.00615 17.5 4.16669 16.6605 4.16669 15.625L4.16669 7.49999C4.16669 6.46446 5.00615 5.62499 6.04169 5.62499L11.6667 5.62499C12.7022 5.62499 13.5417 6.46445 13.5417 7.49999L13.5417 15.625C13.5417 16.6605 12.7022 17.5 11.6667 17.5Z"
      stroke-width="1.67"
      stroke-linecap="round"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
