<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Icon"
      d="M3.33325 12.6703L3.33325 15.7438C3.33325 16.2095 3.50885 16.6562 3.82141 16.9856C4.13397 17.315 4.55789 17.5 4.99992 17.5H14.9999C15.4419 17.5 15.8659 17.315 16.1784 16.9856C16.491 16.6562 16.6666 16.2095 16.6666 15.7438V12.6703M10.0008 2.5V12.4521M10.0008 12.4521L13.8104 8.64941M10.0008 12.4521L6.19132 8.64941"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
.svg {
  stroke: #fff;
}
</style>
