<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 7.76V10.3749C10 10.5446 10.0674 10.7074 10.1874 10.8274L11.6 12.24M2 5.84V5.2C2 3.43269 3.43269 2 5.2 2H5.84M14.16 2L14.8 2C16.5673 2 18 3.43269 18 5.2V5.84M4.88 15.76L2.64 18M17.36 18L15.12 15.76M17.04 10.64C17.04 14.5281 13.8881 17.68 10 17.68C6.11191 17.68 2.96 14.5281 2.96 10.64C2.96 6.75191 6.11191 3.6 10 3.6C13.8881 3.6 17.04 6.75191 17.04 10.64Z"
      stroke-width="1.67"
      stroke-linecap="round"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
