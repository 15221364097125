<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3158 8.73684V6.36842C16.3158 5.81613 15.8681 5.36842 15.3158 5.36842H9.99316C9.72794 5.36842 9.47359 5.26306 9.28605 5.07553L8.18763 3.9771C8.00009 3.78956 7.74574 3.68421 7.48052 3.68421H3C2.44772 3.68421 2 4.13192 2 4.68421V15.3158C2 15.8681 2.44772 16.3158 3 16.3158H3.81347C4.20716 16.3158 4.52632 15.9966 4.52632 15.6029V15.6029C4.52632 15.5175 4.54169 15.4327 4.5717 15.3526L6.8093 9.38572C6.95567 8.99541 7.32879 8.73684 7.74563 8.73684H16.6126C17.2951 8.73684 17.7771 9.40554 17.5613 10.0531L15.7016 15.632C15.5655 16.0404 15.1834 16.3158 14.7529 16.3158H3.68421"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
