<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2195 15.6423C17.2195 15.6423 16.2539 16.5906 16.0173 16.8686C15.6318 17.28 15.1777 17.4742 14.5823 17.4742C14.5251 17.4742 14.464 17.4742 14.4068 17.4704C13.2733 17.398 12.22 16.9562 11.43 16.5792C9.26988 15.5356 7.37312 14.0541 5.79694 12.1764C4.49554 10.6111 3.6254 9.16381 3.04912 7.60989C2.69419 6.66155 2.56444 5.92268 2.62168 5.2257C2.65985 4.78009 2.83159 4.41066 3.14835 4.09454L4.44975 2.7958C4.63675 2.62061 4.8352 2.52539 5.02984 2.52539C5.27027 2.52539 5.46491 2.67012 5.58704 2.79199C5.59085 2.7958 5.59467 2.79961 5.59849 2.80342C5.83129 3.02051 6.05264 3.24522 6.28544 3.48516C6.40375 3.60704 6.52587 3.72891 6.648 3.8546L7.68988 4.89435C8.09442 5.29806 8.09442 5.67131 7.68988 6.07502C7.57921 6.18547 7.47235 6.29592 7.36167 6.40256C7.04109 6.7301 7.29292 6.47878 6.9609 6.77586C6.95326 6.78347 6.94563 6.78728 6.94181 6.7949C6.6136 7.12244 6.67466 7.44236 6.74336 7.65946C6.74718 7.67088 6.75099 7.68231 6.75481 7.69373C7.02577 8.34881 7.40742 8.96581 7.98751 9.70087L7.99133 9.70468C9.04466 10.9996 10.1552 12.0089 11.3803 12.782C11.5368 12.8811 11.6971 12.961 11.8497 13.0372C11.9871 13.1058 12.1169 13.1705 12.2275 13.2391C12.2428 13.2467 12.2581 13.2581 12.2733 13.2657C12.4031 13.3305 12.5252 13.361 12.6512 13.361C12.9679 13.361 13.1664 13.1629 13.2313 13.0982L13.9793 12.3516C14.1091 12.2221 14.3152 12.066 14.5556 12.066C14.7922 12.066 14.9869 12.2145 15.1052 12.344C15.109 12.3478 15.109 12.3478 15.1128 12.3516L17.2157 14.4502C17.6087 14.8386 17.2195 15.6423 17.2195 15.6423Z"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
