import axiosInstance from './axiosService'

export async function updateTask(contact, taskId, taskField, taskValue) {
  contact.tasks.find((task) => taskId === task.id)[taskField] = taskValue
  await axiosInstance.post(`/api/prospects/${contact.id}/update-task`, {
    taskId,
    taskField,
    taskValue
  })
  console.log('Task updated')
}
