import { sortByDescendingDate } from '@/helpers/date'
import axiosInstance from './axiosService'

export async function fetchTasks(contact) {
  console.log('fetchTasks')
  contact.taskLoading = true
  try {
    const { data } = await axiosInstance.get(`/api/prospects/${contact.id}/tasks`)

    contact.tasks = sortByDescendingDate(data.tasks)
  } catch (error) {
    console.error('Error fetching tasks:', error)
    // Handle error, e.g., show an error message to the user
  } finally {
    contact.taskLoading = false
  }
}
