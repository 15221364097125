<template>
  <div>
    <div class="add-note__button-section">
      <div class="add-note__button" @click="createNewNote">
        <div class="plus-icon">
          <PlusIcon color="#828282" size="20" />
        </div>
        <div class="add-note__button-text">Add a new note</div>
      </div>
    </div>
    <div class="note__section-body" v-if="pinnedNotes.length">
      <div v-for="note in pinnedNotes" :key="note.id" class="note__section-body">
        <prospect-note
          :note="note"
          @save-note="saveNote"
          @delete-note="deleteNote"
          @pin-note="pinNote"
          :import-source="importSource"
        />
      </div>
    </div>
    <div class="loader-section" v-if="loading">
      <Loader></Loader>
    </div>
    <div v-for="note in regularNotes" :key="note.id" class="note__section-body">
      <prospect-note
        :note="note"
        @save-note="saveNote"
        @delete-note="deleteNote"
        @pin-note="pinNote"
        :import-source="importSource"
      />
    </div>
  </div>
</template>

<script setup>
import PlusIcon from './icons/PlusIcon.vue'
import Loader from './Loader.vue'
import ProspectNote from './ProspectNote.vue'

import { computed, ref, watch } from 'vue'

const props = defineProps({
  notes: {
    type: Object,
    required: true
  },
  loading: {
    type: Boolean,
    required: true
  },
  importSource: {
    type: String,
    required: true
  }
})

const pinnedNotes = computed(() => props.notes.filter((note) => note.pinned))
const regularNotes = computed(() => props.notes.filter((note) => !note.pinned))

const emit = defineEmits(['createNewNote', 'saveNote', 'deleteNote', 'pinNote'])

function createNewNote() {
  emit('createNewNote')
}

function saveNote(id, note) {
  emit('saveNote', id, note)
}

function deleteNote(id) {
  emit('deleteNote', id)
}

function pinNote(id) {
  emit('pinNote', id)
}
</script>

<style scoped lang="scss">
.loader-section {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
.note__section-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  .note__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;

    .note__title {
      font-size: 14px;
      font-weight: 500;
    }
    .note__date {
      font-size: 12px;
      color: #667085;
    }
  }
}

.add-note__button-section {
  display: flex;
  margin-bottom: 16px;
  .add-note__button {
    padding: 4px 16px 4px 8px;
    transition: 0.05s ease;
    display: flex;
    &:hover {
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.07);
      opacity: 1;
      cursor: pointer;
    }

    .plus-icon {
      display: flex;
      align-items: center;
      margin-right: 5px;
    }

    .add-note__button-text {
      color: #828282;
    }
  }
}
</style>
