<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.66412 9.31444C3.88565 10.8365 4.64778 12.228 5.81108 13.2342C6.97438 14.2405 8.46109 14.7943 9.99921 14.7943M9.99921 14.7943C11.5373 14.7943 13.024 14.2405 14.1873 13.2342C15.3506 12.228 16.1128 10.8365 16.3343 9.31444M9.99921 14.7943V18.0002M10.0001 2.00015C9.27267 2.00015 8.57502 2.28913 8.06063 2.80352C7.54624 3.3179 7.25727 4.01556 7.25727 4.74301V8.40015C7.25727 9.1276 7.54624 9.82526 8.06063 10.3396C8.57502 10.854 9.27267 11.143 10.0001 11.143C10.7276 11.143 11.4252 10.854 11.9396 10.3396C12.454 9.82526 12.743 9.1276 12.743 8.40015V4.74301C12.743 4.01556 12.454 3.3179 11.9396 2.80352C11.4252 2.28913 10.7276 2.00015 10.0001 2.00015Z"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
