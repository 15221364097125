<template>
  <div class="pinned-badge">
    <i class="pi pi-thumbtack pinned-icon"></i>
    Pinned
  </div>
</template>

<style scoped lang="scss">
.pinned-badge {
  display: flex;
  align-items: center;
  align-self: self-start;
  background-color: #ababab;
  color: #ffffff;
  border-radius: 4px;
  padding: 2px 4px;
  line-height: normal;
  font-size: 12px;
  font-weight: 500;
  .pinned-icon {
    margin-right: 4px;
    font-size: 12px;
  }
}
</style>
