<template>
  <transition appear>
    <div class="notification">
      <div class="notification__caller">
        <div class="notification__title">Calling...</div>
        <div
          class="notification__caller-link--deactivated"
          :class="{ 'notification__caller-link': externalLinkActivated }"
        >
          <div class="notification__caller-group">
            <div class="notification__caller-name">
              {{ name }}
            </div>
            <external-link-icon
              v-if="externalLinkActivated"
              class="external-link-icon"
            ></external-link-icon>
          </div>
          <div class="notification__phone-number">{{ phoneNumber }}</div>
        </div>
      </div>
      <div class="notification__actions">
        <div @click="$emit('endCall')" class="notification__hang-up-button">
          <HangUpIcon class="hang-up-icon" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import ExternalLinkIcon from './icons/ExternalLinkIcon.vue'
import HangUpIcon from './icons/HangUpIcon.vue'
import MuteIcon from './icons/MuteIcon.vue'
import PhoneIcon from './icons/PhoneIcon.vue'

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  phoneNumber: {
    type: String,
    required: true
  }
})

const externalLinkActivated = false
</script>

<style scoped>
.notification {
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  padding: 18px 28px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
  opacity: 1;
  margin-top: 10px;
}
.notification__caller-group {
  display: flex;
  align-items: center;
  width: 100%;
}
.notification__caller {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 279px;
}
.notification__title {
  font-size: 14px;
  color: #b8b8b8;
}
.notification__caller-link--deactivated {
  width: 100%;
}
.notification__caller-name {
  font-size: 16px;
  font-weight: 500;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.notification__phone-number {
  font-size: 14px;
}
.notification__actions {
  display: flex;
  align-items: center;
}
.notification__hang-up-button {
  background: #f24646;
  border-radius: 50%;
  padding: 12px;
  display: flex;
  margin-left: 12px;
}

.notification__hang-up-button:hover {
  cursor: pointer;
  background: #ea3a3a;
}

.v-enter-active,
.v-leave-active {
  transition:
    opacity 0.27s ease,
    transform 0.27s ease;
}

.v-enter-from,
.v-leave-to {
  transform: translateX(180px);
  opacity: 0;
}
.notification__caller-link:hover {
  cursor: pointer;
}

.external-link-icon {
  stroke: #fff;
}

.hang-up-icon {
  stroke: #fff;
  fill: #fff;
}
</style>
