<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.402 6.73284C17.7218 8.82821 17.9082 11.617 16.6527 14.0848M13.6216 8.10339C14.7356 9.40549 14.8929 11.1385 13.8332 12.6721M10 5L6.32313 7.907H2.5V12.0926L6.32313 12.0915L10 15V5Z"
      :stroke="color"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#344054' // Default color
    },
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
