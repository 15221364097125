import * as Sentry from '@sentry/vue'

// Set user information
export function setUser({ id, username, email, companyId }) {
  Sentry.setUser({
    id: id,
    username: username,
    email: email,
    companyId: companyId
  })
}

// Clear user information
export function clearUser() {
  Sentry.setUser(null)
}
