<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Icon"
      d="M14.1699 7L8.20366 13L6.16992 10.9548"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
svg {
  stroke: #344054;
}
</style>
