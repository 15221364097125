<template>
  <div class="prospect__note" :class="{ 'prospect__note--pinned': note.pinned }">
    <div class="note__header">
      <div class="note__title">
        {{ note.title }}
        <pinned-badge v-if="note.pinned" class="pinned-badge" />
      </div>
      <div class="note__meta">
        <div class="note__date">{{ formatDate(note.date) }}</div>
        <div class="note__menu" ref="threeDotMenu">
          <div class="menu-button" @click="toggleMenu">
            <ThreeDotIcon size="18" class="three-dot-icon" />
          </div>
          <div v-if="menuVisible" class="menu-dropdown">
            <ul>
              <li @click="deleteNote(note.id)">
                <TrashIcon class="menu-icon" size="20" />
                Delete
              </li>
              <li v-if="importSource === 'hubspot' && !note.pinned" @click="pinNote(note.id)">
                <PinnedIcon class="menu-icon" size="16" style="width: 20px" />
                Pin
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Textarea
      v-model="noteCopy.body"
      autoResize
      rows="2"
      cols="30"
      @blur="saveNote(note.id, noteCopy.body)"
      variant="filled"
    />
  </div>
</template>

<script setup>
import { formatDate } from '../helpers/date'
import PinnedBadge from './PinnedBadge.vue'
import ThreeDotIcon from './icons/ThreeDotIcon.vue'
import TrashIcon from './icons/TrashIcon.vue'
import PinnedIcon from './icons/PinIcon.vue'
import Textarea from 'primevue/textarea'
import { onClickOutside } from '@vueuse/core'

import { ref, watch } from 'vue'

const props = defineProps({
  note: {
    type: Object,
    required: true
  },
  importSource: {
    type: String,
    required: true
  }
})

const menuVisible = ref(false)

function toggleMenu() {
  menuVisible.value = !menuVisible.value
}

const threeDotMenu = ref(null)

onClickOutside(threeDotMenu, () => {
  menuVisible.value = false
})

function deleteNote() {
  emit('deleteNote', props.note.id)
  menuVisible.value = false
}

function pinNote() {
  emit('pinNote', props.note.id)
  menuVisible.value = false
}

const noteCopy = ref([])

noteCopy.value = JSON.parse(JSON.stringify(props.note))

watch(
  () => props.note,
  () => {
    noteCopy.value = JSON.parse(JSON.stringify(props.note))
  }
)

const emit = defineEmits(['saveNote', 'deleteNote', 'pinNote'])

function saveNote(id, note) {
  if (props.note.body !== note) {
    emit('saveNote', id, note)
  }
}
</script>

<style scoped lang="scss">
.prospect__note {
  display: flex;
  flex-direction: column;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 16px;
  &--pinned {
    border: 1px solid #ababab;
  }
}
.note__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .note__title {
    font-size: 14px;
    font-weight: 500;
    display: flex;

    .pinned-badge {
      margin-left: 12px;
    }
  }
  .note__meta {
    display: flex;
    gap: 4px;
  }
  .note__date {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #667085;
  }
}

.note__menu {
  transition: 0.05s ease;
  position: relative;

  .three-dot-icon {
    fill: #787878;
  }

  .menu-button {
    transition: 0.05s ease;
    padding: 4px;
    display: flex;
    align-items: center;

    &:hover {
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.07);

      cursor: pointer;
    }
  }

  .menu-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 100;

    color: #344054;
    font-size: 14px;
    font-weight: 500;

    ul {
      list-style: none;
      padding: 3px;
      margin: 0;

      li {
        cursor: pointer;
        font-size: 14px;

        transition: 0.05s ease;
        padding: 4px 8px 4px 4px;
        display: flex;
        align-items: center;
        gap: 8px;

        &:hover {
          border-radius: 4px;
          background: #ededed;
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }
}
.menu-icon {
  stroke: #334155;
}
</style>
