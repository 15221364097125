<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 6L10 14M14 10L6 10" :stroke="color" stroke-width="1.67" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#344054' // Default color
    },
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
