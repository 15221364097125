<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.63988 10.6642C1.65552 10.9096 1.65897 11.8281 1.65889 12.611C1.65882 13.2764 2.08495 13.8175 2.61173 13.8175L5.08613 13.8175C5.6108 13.8175 6.03613 13.2803 6.03613 12.6175V11.4061C6.03613 11.1138 6.20183 10.8646 6.43046 10.8193C7.21544 10.6638 9.00982 10.3349 10.3101 10.3C11.7759 10.2606 13.8235 10.6427 14.6705 10.8166C14.8974 10.8632 15.0611 11.1113 15.0611 11.4016V12.6357C15.0611 13.2913 15.4777 13.8256 15.9966 13.8356L18.3805 13.8816C18.9073 13.8918 19.3401 13.3584 19.3449 12.6928L19.3604 10.5814C19.3608 10.0289 19.2242 9.55654 18.9426 9.13426C18.5005 8.4759 17.9003 7.94421 16.9997 7.42621C15.5276 6.57284 12.9902 5.50008 10.3111 5.5C7.58615 5.49992 5.00995 6.91256 5.00995 6.91256C5.00995 6.91256 3.04632 7.95674 2.21304 8.88298C1.80162 9.40267 1.622 9.96865 1.63988 10.6642Z"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 21 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
