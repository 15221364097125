<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4651 11.6672C11.9026 11.813 12.3755 11.5765 12.5213 11.1391C12.6671 10.7016 12.4307 10.2287 11.9932 10.0828L11.4651 11.6672ZM8.91666 9.9375H8.08166C8.08166 10.2969 8.31164 10.616 8.65261 10.7297L8.91666 9.9375ZM9.75166 6.01739C9.75166 5.55623 9.37781 5.18239 8.91666 5.18239C8.4555 5.18239 8.08166 5.55623 8.08166 6.01739H9.75166ZM11.9932 10.0828L9.18071 9.14535L8.65261 10.7297L11.4651 11.6672L11.9932 10.0828ZM9.75166 9.9375V6.01739H8.08166V9.9375H9.75166ZM15.5817 9C15.5817 12.681 12.5976 15.665 8.91666 15.665V17.335C13.5199 17.335 17.2517 13.6033 17.2517 9H15.5817ZM8.91666 15.665C5.23568 15.665 2.25166 12.681 2.25166 9H0.581657C0.581657 13.6033 4.31336 17.335 8.91666 17.335V15.665ZM2.25166 9C2.25166 5.31902 5.23568 2.335 8.91666 2.335V0.665C4.31336 0.665 0.581657 4.39671 0.581657 9H2.25166ZM8.91666 2.335C12.5976 2.335 15.5817 5.31902 15.5817 9H17.2517C17.2517 4.39671 13.5199 0.665 8.91666 0.665V2.335Z"
      stroke-width="1.67"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
