<template>
  <div :class="{ container: emails.length === 0 }">
    <div class="email__section-body" v-if="pinnedEmail">
      <prospect-email :email="pinnedEmail" />
    </div>
    <div class="loader-section" v-if="emails.length === 0 && loading">
      <Loader></Loader>
    </div>
    <div class="empty-state" v-else-if="emails.length === 0">There is no email yet</div>
    <div v-for="email in regularEmails" :key="email.id" class="email__section-body">
      <prospect-email :email="email" />
    </div>
  </div>
</template>

<script setup>
import Loader from './Loader.vue'
import ProspectEmail from './ProspectEmail.vue'

import { computed } from 'vue'

const props = defineProps({
  emails: {
    type: Object,
    required: true
  },
  loading: {
    type: Boolean,
    required: true
  }
})

const pinnedEmail = computed(() => props.emails.find((email) => email.pinned))
const regularEmails = computed(() => props.emails.filter((email) => !email.pinned))
</script>

<style scoped lang="scss">
.container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.loader-section {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
.email__section-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
</style>
