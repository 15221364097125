<template>
  <div>
    <div class="progress__badge-section">
      <div class="progress__badge progress__badge--connected">Connected {{ connected }}</div>
      <div class="progress__badge progress__badge--attempted">Attempted {{ attempted }}</div>
      <div class="progress__badge progress__badge--pending">Pending {{ pending }}</div>
    </div>
    <div class="progress-bar">
      <div
        v-for="(segment, index) in segments"
        :key="index"
        :style="{
          width: segment.percentage + '%',
          backgroundColor: segment.color
        }"
        class="progress-segment"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

// Define props
const props = defineProps({
  connected: Number,
  pending: Number,
  attempted: Number,
  total: Number
})

const segments = computed(() =>
  [
    { percentage: (props.connected / props.total) * 100, color: '#14ba6d' },
    { percentage: (props.attempted / props.total) * 100, color: '#ababab' }
  ].filter((item) => item.percentage > 0)
)
</script>

<style scoped lang="scss">
.progress__badge-section {
  color: #313131;
  font-size: 12px;
  font-weight: 500;
  align-self: self-start;
  display: flex;
  width: 100%;

  .progress__badge {
    border-radius: 4px;
    padding: 0 4px;
    margin-right: 12px;
  }
  .progress__badge:last-child {
    margin-right: 0;
  }
  .progress__badge--connected {
    background-color: #14ba6d;
    color: #fff;
  }

  .progress__badge--attempted {
    background-color: #ababab;
    color: #fff;
  }

  .progress__badge--pending {
    color: #4a4a4a;
    background-color: #f2f2f2;
  }
}

.progress-bar {
  display: flex;
  width: 100%;
  height: 8px;
  background-color: #f2f2f2;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 8px;
}

.progress-segment {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  text-align: center;
  transition: width 0.5s ease;
}
.progress-segment:not(:last-child) {
  border-right: 1px solid #fff;
}
</style>
