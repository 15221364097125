<template>
  <div class="admin-analytics-list">
    <div class="filter">
      <analytics-date-range-selector @update:value="updateDateValue" />
    </div>
    <div class="admin-session-analytics-table">
      <div v-if="userAnalytics.length" class="prime-table">
        <DataTable :value="userAnalytics" style="width: 100%" scrollable scrollHeight="flex">
          <Column
            field="userName"
            header="Name"
            headerClass="data-table-header"
            bodyClass="cell cell-first-column"
            style="min-width: 195px"
            frozen
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="company"
            header="Company"
            headerClass="data-table-header"
            bodyClass="cell"
            style="min-width: 195px"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="userCost"
            header="Cost"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="totalCalls"
            header="Dials"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="callsPerDay"
            header="Dials / day"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="connectedCalls"
            header="Connects"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="connectRate"
            header="% Connect"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="callbacks"
            header="Callbacks"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="callbackRate"
            header="% Callback"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="connectedCallback"
            header="Callback connects"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="connectedCallbackRate"
            header="% Callback connect"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="conversations"
            header="Conversations"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="conversationRate"
            header="% Conversation"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="meetingBookedCount"
            header="Meetings"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="meetingBookedRate"
            header="% Meeting"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="talkTime"
            header="Talk time"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="averageTalkTime"
            header="Avg talk time"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>
          <Column
            field="sessionTime"
            header="Session time"
            headerClass="data-table-header"
            bodyClass="cell"
            sortable
          >
            <template #sorticon="slotProps">
              <SortIcon :sorted="!!slotProps.sorted" :sortOrder="slotProps.sortOrder" />
            </template>
          </Column>

          <ColumnGroup type="footer">
            <Row>
              <Column class="column--total" footer="Total" frozen />
              <Column class="column--total" footer="" />
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.userCost }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.totalCalls }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.callsPerDay }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.connectedCalls }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.connectRate }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.callbacks }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.callbackRate }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.connectedCallback }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.connectedCallbackRate }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.conversations }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.conversationRate }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.meetingBookedCount }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.meetingBookedRate }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.talkTime }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.averageTalkTime }}</template>
              </Column>
              <Column class="column--total" headerClass="data-table-header" bodyClass="cell">
                <template #footer>{{ total.totalSessionTime }}</template>
              </Column>
            </Row>
          </ColumnGroup>
        </DataTable>
      </div>
      <div v-else class="table__row-group--empty">
        <Loader></Loader>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Row from 'primevue/row'
import ColumnGroup from 'primevue/columngroup'
import Loader from './components/Loader.vue'
import SortIcon from './components/SortIcon.vue'
import axios from './services/axiosService'
import { useAuthStore } from './stores/auth'
import AnalyticsDateRangeSelector from './components/AnalyticsDateRangeSelector.vue'

const authStore = useAuthStore()

function updateDateValue(updatedDate) {
  fetchAnalytics(updatedDate)
}

const userAnalytics = ref([])
const total = ref([])

async function fetchAnalytics({ startDate, endDate }) {
  try {
    const response = await axios.post(`/api/analytics/admin-only`, {
      startDate,
      endDate
    })
    userAnalytics.value = response.data.analytics
    total.value = response.data.total
  } catch (error) {
    console.error('Failed to fetch analytics:', error)
  }
}
</script>

<style lang="scss">
.admin-analytics-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 0;
  flex: 1 1 auto;

  --p-datatable-footer-border-width: 0;
  .filter {
    margin-bottom: 16px;
    display: flex;
  }
  .admin-session-analytics-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    align-items: start;

    border: 1px solid #eaecf0;
    border-radius: 8px;

    min-height: 0;
    flex: 1 1 auto;

    .cell {
      &:hover {
        cursor: unset;
      }
    }

    .prime-table {
      min-height: 0;
      flex: 1 1 auto;
      width: 100%;
      border-radius: 8px;
    }

    .table__row-group--empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
    }
    .p-datatable-table-container {
      border-radius: 8px;
    }
  }

  .column--total {
    font-size: 14px;
    font-weight: 600;
    border: none;
    padding-bottom: 16px;
  }
}
</style>
