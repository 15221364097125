import axiosInstance from './axiosService'

export async function updateLog(contact, logId, body) {
  await axiosInstance.post(`/api/prospects/${contact.id}/update-call-log`, {
    logId,
    body
  })
  contact.callLogs.find((log) => log.id === logId).body = body
  console.log('Log updated')
}
