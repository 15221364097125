<template>
  <div class="session-list">
    <TheSessionTitle :items="items" />
    <router-view></router-view>
  </div>
</template>

<script setup>
import TheSessionTitle from './components/TheSessionTitle.vue'
import { useRoute } from 'vue-router'
import { useSessionStore } from './stores/sessionStore'
import { computed } from 'vue'
import router from './router'

const sessionStore = useSessionStore()

const route = useRoute()

const sessionId = computed(() => route.params.sessionId)

const items = computed(() => {
  if (sessionStore.sessions[sessionId.value]?.sessionName) {
    return [
      { label: 'Sessions', command: redirectToSessionList },
      { label: sessionStore.sessions[sessionId.value]?.sessionName }
    ]
  }
  return [{ label: 'Sessions', command: redirectToSessionList }]
})

function redirectToSessionList() {
  router.push('/sessions')
}
</script>

<style lang="scss">
.session-list {
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1 1 auto;
  width: 100%;
}
</style>
