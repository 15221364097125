<template>
  <div class="analytics-date-selector">
    <div class="date-selector-dropdown" ref="dateSelectorDropdown">
      <div @click="toggleDropdown" class="dropdown__button">
        <div class="dropdown__buttonText">{{ optionLabels[selectedOption] }}</div>
        <ChevronDownIcon class="dropdown__button-svg" v-if="!isOpen" color="#D0D5DD" size="12" />
        <ChevronUpIcon class="dropdown__button-svg" v-else color="#D0D5DD" size="12" />
      </div>
      <div v-if="isOpen" class="dropdown--open" @click.stop="">
        <div class="dropdown__main">
          <div
            class="dropdown__item"
            v-for="(label, value) in optionLabels"
            :key="value"
            @click="selectDateOption(value)"
          >
            <div
              class="dropdown__item-group"
              :class="{
                'dropdown__item--selected': value === selectedOption,
                'dropdown__item--unselected': value !== selectedOption
              }"
            >
              <div v-if="value === selectedOption" class="checkmark-icon">
                <CheckmarkIcon size="21" />
              </div>
              <div class="dropdown__item-text">
                {{ label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VueDatePicker
      v-if="selectedOption === 'custom'"
      class="date-picker"
      v-model="customDate"
      :enable-time-picker="false"
      :highlight-week-days="[0, 6]"
      @cleared="selectedOption = 'today'"
      :transitions="false"
      input-class-name="dp-custom-input"
      menu-class-name="dp-custom-menu"
      range
      auto-apply
    ></VueDatePicker>
    <div class="date-text" v-if="startDate && endDate && selectedOption !== 'custom'">
      {{ formatDateWithoutTime(startDate) }}
      <div v-if="!isSameDay(startDate, endDate)">&nbsp;- {{ formatDateWithoutTime(endDate) }}</div>
    </div>
  </div>
</template>

<script setup>
import { formatDateWithoutTime } from '../helpers/date'
import { ref, watch, computed, onMounted } from 'vue'
import {
  startOfDay,
  endOfDay,
  subDays,
  startOfWeek,
  endOfWeek,
  subWeeks,
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfQuarter,
  endOfQuarter,
  subQuarters,
  startOfYear,
  endOfYear,
  subYears,
  isSameDay,
  formatISO
} from 'date-fns'
import CheckmarkIcon from './icons/CheckmarkIcon.vue'
import ChevronDownIcon from './icons/ChevronDownIcon.vue'
import ChevronUpIcon from './icons/ChevronUpIcon.vue'
import { onClickOutside } from '@vueuse/core'

const isOpen = ref(false)

function toggleDropdown() {
  isOpen.value = !isOpen.value
}

const dateSelectorDropdown = ref(null)

function closeDropdown() {
  isOpen.value = false
}

onClickOutside(dateSelectorDropdown, () => {
  closeDropdown()
})

function selectDateOption(value) {
  selectedOption.value = value
  isOpen.value = false
}

const emit = defineEmits(['update:value'])

const props = defineProps({
  initialValue: {
    type: String,
    default: 'today'
  },
  initialCustomStartDate: {
    type: String
  },
  initialCustomEndDate: {
    type: String
  }
})

const selectedOption = ref(props.initialValue)
const customDate =
  props.initialValue === 'custom'
    ? ref([props.initialCustomStartDate, props.initialCustomEndDate])
    : ref([])

onMounted(() => {
  emit('update:value', calculatePeriod())
})

watch(
  () => selectedOption.value,
  (newValue) => {
    if (newValue === 'custom') {
      customDate.value = [startDate.value, endDate.value]
    }
  }
)

watch([() => selectedOption.value, () => customDate.value], () => {
  emit('update:value', calculatePeriod())
})

const optionLabels = computed(() => {
  return {
    today: 'Today',
    yesterday: 'Yesterday',
    this_week_so_far: 'This week so far',
    last_week: 'Last week',
    this_month_so_far: 'This month so far',
    last_month: 'Last month',
    // this_quarter_so_far: 'This quarter so far',
    // last_quarter: 'Last quarter',
    this_year_so_far: 'This year so far',
    last_year: 'Last year',
    last_7_days: 'Last 7 days',
    last_14_days: 'Last 14 days',
    last_30_days: 'Last 30 days',
    // last_90_days: 'Last 90 days',
    last_365_days: 'Last 365 days',
    custom: 'Custom'
  }
})

const startDate = ref('')
const endDate = ref('')

function calculatePeriod() {
  const startOfWeekOptions = { weekStartsOn: 1 }

  switch (selectedOption.value) {
    case 'today':
      startDate.value = startOfDay(new Date())
      endDate.value = endOfDay(new Date())
      break
    case 'yesterday':
      startDate.value = startOfDay(subDays(new Date(), 1))
      endDate.value = endOfDay(subDays(new Date(), 1))
      break
    case 'this_week_so_far':
      startDate.value = startOfWeek(new Date(), startOfWeekOptions)
      endDate.value = new Date() // Considered as end of today
      break
    case 'last_week':
      startDate.value = startOfWeek(subWeeks(new Date(), 1), startOfWeekOptions)
      endDate.value = endOfWeek(subWeeks(new Date(), 1), startOfWeekOptions)
      break
    case 'this_month_so_far':
      startDate.value = startOfMonth(new Date())
      endDate.value = new Date() // Considered as end of today
      break
    case 'last_month':
      startDate.value = startOfMonth(subMonths(new Date(), 1))
      endDate.value = endOfMonth(subMonths(new Date(), 1))
      break
    case 'this_quarter_so_far':
      startDate.value = startOfQuarter(new Date())
      endDate.value = new Date() // Considered as end of today
      break
    case 'last_quarter':
      startDate.value = startOfQuarter(subQuarters(new Date(), 1))
      endDate.value = endOfQuarter(subQuarters(new Date(), 1))
      break
    case 'this_year_so_far':
      startDate.value = startOfYear(new Date())
      endDate.value = new Date() // Considered as end of today
      break
    case 'last_year':
      startDate.value = startOfYear(subYears(new Date(), 1))
      endDate.value = endOfYear(subYears(new Date(), 1))
      break
    case 'last_7_days':
      startDate.value = startOfDay(subDays(new Date(), 7)) // Includes today in the 7-day range
      endDate.value = endOfDay(subDays(new Date(), 1))
      break
    case 'last_14_days':
      startDate.value = startOfDay(subDays(new Date(), 14)) // Starts 14 days ago from today
      endDate.value = endOfDay(subDays(new Date(), 1)) // Ends yesterday
      break
    case 'last_30_days':
      startDate.value = startOfDay(subDays(new Date(), 30)) // Starts 30 days ago from today
      endDate.value = endOfDay(subDays(new Date(), 1)) // Ends yesterday
      break
    case 'last_90_days':
      startDate.value = startOfDay(subDays(new Date(), 90)) // Starts 90 days ago from today
      endDate.value = endOfDay(subDays(new Date(), 1)) // Ends yesterday
      break
    case 'last_365_days':
      startDate.value = startOfDay(subDays(new Date(), 365)) // Starts 365 days ago from today
      endDate.value = endOfDay(subDays(new Date(), 1)) // Ends yesterday
      break
    case 'custom':
      startDate.value = startOfDay(new Date(customDate.value[0])) // Assuming customDate is the desired start date
      endDate.value = endOfDay(new Date(customDate.value[1])) // Assuming a single day range for customDate
      break
    default:
      startDate.value = startOfDay(new Date())
      endDate.value = endOfDay(new Date())
  }

  return {
    startDate: formatISO(startDate.value, { representation: 'complete' }),
    endDate: formatISO(endDate.value, { representation: 'complete' }),
    optionName: selectedOption.value
  }
}
</script>

<style lang="scss">
.analytics-date-selector {
  display: flex;
  user-select: none;
  .date-text {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: #667085;
    text-wrap: nowrap;
  }
  .date-picker {
    width: 250px;
    margin-right: 12px;
  }

  .date-selector-dropdown {
    margin-right: 12px;
    position: relative;
    display: inline-block;
    background: #fff;
    color: #344054;
  }
  .date-selector-dropdown .dropdown__button {
    padding: 6px 12px;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: opacity 0.4s ease;
  }
  .dropdown__button:hover {
    border: 1px solid #c2c2c2;
    cursor: pointer;
  }
  .dropdown__buttonText {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    margin-right: 8px;
  }

  .dropdown--open {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #344054;
    font-size: 14px;
    display: inline-block;
    font-weight: 500;
    overflow: visible;
    background: #fff;
    z-index: 2;
    min-width: 100%;
  }

  .dropdown__main {
    padding: 4px;
  }

  .dropdown__item-group {
    display: flex;
    align-items: center;
  }
  .dropdown__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.05s ease;
    padding: 4px 4px 4px 0;
  }
  .dropdown__item-text {
    /* max-width: 155px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown__item:hover {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.07);
    opacity: 1;
    cursor: pointer;
  }
  .checkmark-icon {
    display: flex;
  }
  .dropdown__item--selected {
    padding-left: 0;
    font-weight: 600;
  }
  .dropdown__item--unselected {
    padding-left: 21px;
  }
  .dropdown__button-svg {
    pointer-events: none;
  }

  .dp__theme_light {
    --dp-icon-color: #828282;
  }

  .dp-custom-input {
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    padding: 6px 12px 6px 35px;
    --dp-font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
    color: #344054;

    &:hover {
      border: 1px solid #c2c2c2;
    }
  }
  .dp-custom-menu {
    border-radius: 8px;
  }
}
</style>
