<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.60864 11.5256C10.0196 11.8643 10.9427 12.5061 11.3803 12.7823C11.5367 12.8813 11.697 12.9613 11.8497 13.0375C11.9871 13.106 12.1168 13.1708 12.2275 13.2393C12.2428 13.2469 12.258 13.2584 12.2733 13.266C12.4031 13.3307 12.5252 13.3612 12.6511 13.3612C12.9679 13.3612 13.1664 13.1632 13.2312 13.0984L13.9793 12.3519C14.1091 12.2224 14.3151 12.0662 14.5556 12.0662C14.7922 12.0662 14.9868 12.2148 15.1051 12.3442L17.2156 14.4504C17.6087 14.8389 17.2194 15.6425 17.2194 15.6425C17.2194 15.6425 16.2539 16.5909 16.0173 16.8689C15.6318 17.2802 15.1777 17.4745 14.5823 17.4745C14.5251 17.4745 14.464 17.4745 14.4067 17.4706C13.2733 17.3983 12.2199 16.9565 11.4299 16.5794C10.0749 15.9248 8.49529 14.6475 7.36164 13.6585M5.02981 11.2C4.14157 9.99616 3.50384 8.83635 3.04909 7.61014C2.69416 6.66179 2.56441 5.92292 2.62165 5.22594C2.65982 4.78034 2.83156 4.4109 3.14832 4.09479L4.44971 2.79605C4.63672 2.62085 4.83517 2.52563 5.02981 2.52563C5.27024 2.52563 5.46488 2.67036 5.58701 2.79224L5.59846 2.80366C5.83126 3.02076 6.05261 3.24546 6.28541 3.48541C6.40372 3.60728 6.52584 3.72916 6.64797 3.85484L7.68985 4.89459C8.09439 5.29831 8.09439 5.67155 7.68985 6.07527C7.57918 6.18572 7.47231 6.29616 7.36164 6.40281C7.04106 6.73035 7.29289 6.47903 6.96087 6.7761C6.95323 6.78372 6.9456 6.78753 6.94178 6.79514C6.61357 7.12268 6.67463 7.44261 6.74333 7.6597L6.75478 7.69398C6.93197 8.12234 7.15648 8.53442 7.45286 8.97388M2.60864 17.0256L17.1086 2.52563"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
