import axiosInstance from './axiosService'

export async function updateField(contact, field, value) {
  await axiosInstance.post(`/api/prospects/${contact.id}/update-field`, {
    field,
    value
  })
  contact[field] = value
  console.log('Field updated')
}
