<template>
  <div class="multiselect" ref="multiselect">
    <div
      @click="toggleDropdown"
      class="multiselect-input"
      :class="{
        'multiselect-input--placeholder': selectedOptions.length === 0,
        'multiselect-input--chip': selectedOptions.length !== 0
      }"
    >
      <div class="placeholder" v-if="selectedOptions.length === 0">
        {{ placeholder }}
      </div>
      <div class="chip-container">
        <span v-for="option in selectedOptions" :key="option.value" class="chip">
          {{ option.label }}

          <svg
            @click.stop="removeOption(option)"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.0052 7.7019C13.2005 7.50664 13.2005 7.19006 13.0052 6.9948C12.8099 6.79953 12.4934 6.79953 12.2981 6.9948L13.0052 7.7019ZM6.9948 12.2981C6.79953 12.4934 6.79953 12.8099 6.9948 13.0052C7.19006 13.2005 7.50664 13.2005 7.7019 13.0052L6.9948 12.2981ZM12.2981 13.0052C12.4934 13.2005 12.8099 13.2005 13.0052 13.0052C13.2005 12.8099 13.2005 12.4934 13.0052 12.2981L12.2981 13.0052ZM7.7019 6.9948C7.50664 6.79953 7.19006 6.79953 6.9948 6.9948C6.79953 7.19006 6.79953 7.50664 6.9948 7.7019L7.7019 6.9948ZM17 10C17 13.866 13.866 17 10 17V18C14.4183 18 18 14.4183 18 10H17ZM10 17C6.13401 17 3 13.866 3 10H2C2 14.4183 5.58172 18 10 18V17ZM3 10C3 6.13401 6.13401 3 10 3V2C5.58172 2 2 5.58172 2 10H3ZM10 3C13.866 3 17 6.13401 17 10H18C18 5.58172 14.4183 2 10 2V3ZM12.2981 6.9948L9.64645 9.64645L10.3536 10.3536L13.0052 7.7019L12.2981 6.9948ZM9.64645 9.64645L6.9948 12.2981L7.7019 13.0052L10.3536 10.3536L9.64645 9.64645ZM13.0052 12.2981L10.3536 9.64645L9.64645 10.3536L12.2981 13.0052L13.0052 12.2981ZM10.3536 9.64645L7.7019 6.9948L6.9948 7.7019L9.64645 10.3536L10.3536 9.64645Z"
              fill="black"
            />
          </svg>
        </span>
      </div>
      <ChevronDownIcon
        class="dropdown__button-svg"
        v-if="!dropdownOpen"
        color="#D0D5DD"
        size="12"
      />
      <ChevronUpIcon class="dropdown__button-svg" v-else color="#D0D5DD" size="12" />
    </div>

    <div v-if="dropdownOpen" class="multiselect-dropdown">
      <div @click.stop="toggleSelectAll" class="multiselect-option select-all container">
        {{ selectAllLabel }}
        <input type="checkbox" :checked="isAllSelected()" />
        <span class="checkmark"></span>
      </div>
      <div
        v-for="option in props.options"
        :key="option.value"
        @click.stop="toggleOption(option)"
        :class="{ selected: isSelected(option) }"
        class="multiselect-option container"
      >
        {{ option.label }}
        <input type="checkbox" :checked="isSelected(option)" />
        <span class="checkmark"></span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { onClickOutside } from '@vueuse/core'
import ChevronDownIcon from './icons/ChevronDownIcon.vue'
import ChevronUpIcon from './icons/ChevronUpIcon.vue'

const multiselect = ref()
onClickOutside(multiselect, () => {
  dropdownOpen.value = false
})

const props = defineProps({
  options: {
    type: Array,
    required: true
  },
  placeholder: {
    type: String,
    required: true
  },
  modelValue: {
    type: Array,
    default: () => []
  },
  optionLabel: {
    type: String,
    required: true
  },
  optionValue: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['update:modelValue'])

const dropdownOpen = ref(false)
const selectedOptions = ref([...props.modelValue])

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value
}

const toggleOption = (option) => {
  const index = selectedOptions.value.findIndex((selected) => selected.value === option.value)
  if (index >= 0) {
    selectedOptions.value.splice(index, 1)
  } else {
    selectedOptions.value.push(option)
  }
  emit('update:modelValue', selectedOptions.value)
}

const removeOption = (option) => {
  const index = selectedOptions.value.findIndex((selected) => selected.value === option.value)
  if (index >= 0) {
    selectedOptions.value.splice(index, 1)
    emit('update:modelValue', selectedOptions.value)
  }
}

const isSelected = (option) => {
  return selectedOptions.value.some((selected) => selected.value === option.value)
}

const isAllSelected = () => selectedOptions.value.length === props.options.length

const selectAll = () => {
  selectedOptions.value = [...props.options]
  emit('update:modelValue', selectedOptions.value)
}

const unselectAll = () => {
  selectedOptions.value = []
  emit('update:modelValue', selectedOptions.value)
}

const toggleSelectAll = () => {
  if (selectedOptions.value.length === props.options.length) {
    unselectAll()
  } else {
    selectAll()
  }
}

const selectAllLabel = computed(() => {
  return selectedOptions.value.length === props.options.length ? 'Unselect all' : 'Select all'
})

watch(
  () => props.modelValue,
  (newValue) => {
    selectedOptions.value = [...newValue]
  }
)
</script>

<style scoped lang="scss">
.multiselect {
  position: relative;
  background: #fff;
  color: #344054;
  font-size: 14px;
  font-weight: 500;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.multiselect-input {
  display: flex;
  gap: 5px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  display: flex;
  align-items: center;
  transition: opacity 0.4s ease;

  &--placeholder {
    padding: 6px 12px;
  }

  &--chip {
    padding: 3px 12px 3px 6px;
  }

  .chip-container {
    max-width: 400px;
    // display: flex;
    // flex-wrap: wrap;
    overflow: hidden;
    display: flex;
    gap: 5px;
  }

  .placeholder {
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    color: #344054;
  }
  .dropdown__button-svg {
    margin-left: 8px;
  }

  cursor: pointer;
  &:hover {
    border: 1px solid #c2c2c2;
  }
}

.multiselect-dropdown {
  min-width: 200px;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #344054;
  display: inline-block;
  font-weight: 500;
  overflow: visible;
  background: #fff;
  z-index: 2;

  padding: 4px;
}

.multiselect-option {
  display: flex;
  align-items: center;
  transition: 0.05s ease;
  padding: 5px 5px 5px 0;

  &:hover {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.07);
    opacity: 1;
    cursor: pointer;
  }
}

.multiselect-option.selected {
  //   font-weight: 600;
}

.multiselect-option input {
  margin-right: 5px;
  border: 1px solid red;
}

input[type='text'] {
  border: 1px solid red;
}

.select-all {
  //   border-bottom: 1px solid #d0d5dd;
  font-weight: 600;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 5px;
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 7px;
  left: 10px;
  height: 18px;
  width: 18px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #dddddd;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  //   background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #0070ff;
  border-color: #0070ff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.chip {
  display: flex;
  align-items: center;
  cursor: default;
  text-wrap: nowrap;

  svg {
    margin-left: 4px;
    &:hover {
      cursor: pointer;
    }
  }
  padding: 2px 10px;
  color: #181818;
  background-color: #f4f4f4;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
}
</style>
