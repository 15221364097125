import axiosInstance from './axiosService'

export async function pinNote(contact, noteId) {
  await axiosInstance.post(`/api/prospects/${contact.id}/notes/${noteId}/pin`)

  if (contact.notes.find((note) => note.pinned)) {
    contact.notes.find((note) => note.pinned).pinned = false
  }
  contact.notes.find((note) => note.id === noteId).pinned = true

  console.log('Note pinned')
}
