<template>
  <div class="dropdown" ref="dropdown">
    <slot name="button" v-bind="{ toggleDropdown, isOpen }"></slot>
    <div
      v-if="isOpen"
      class="dropdown--open"
      @click.stop=""
      :style="props.minWidth ? { 'min-width': `${props.minWidth}px` } : ''"
    >
      <div class="dropdown__main">
        <div class="dropdown__filter">
          <SearchIcon class="search-icon" size="16" />
          <input
            ref="filterInput"
            autofocus
            type="text"
            class="filter-input"
            v-model="searchText"
            placeholder="Search by name..."
          />
        </div>
        <div class="dropdown__scroll-section">
          <div v-if="itemsFiltered.length === 0">No result</div>
          <div
            class="dropdown__item"
            v-for="item in itemsFiltered"
            :key="item.id"
            @click="selectOption(item)"
          >
            <div class="dropdown__item-group">
              <div class="dropdown__item-text">
                <slot name="item" v-bind="item">{{ item.label }}</slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue'
import { onClickOutside } from '@vueuse/core'
import SearchIcon from './icons/SearchIcon.vue'
import { nextTick } from 'vue'

const props = defineProps({
  items: {
    type: Object,
    required: true
  },
  initialValue: {
    type: Object
  },
  minWidth: {
    type: Number
  },
  disabled: {
    type: Boolean
  }
})

const filterInput = ref(null)

onMounted(() => {
  watch(
    () => isOpen.value,
    (newValue) => {
      if (newValue) {
        searchText.value = ''
        nextTick(() => {
          filterInput.value.focus()
        })
      }
    }
  )
})

const searchText = ref('')
const itemsFiltered = computed(() => {
  const searchWords = searchText.value.toLowerCase().split(/\s+/) // Split search term into words
  return props.items.filter((item) =>
    searchWords.every((word) => item.label.toLowerCase().includes(word))
  )
})

const isOpen = ref(false)

function toggleDropdown() {
  if (props.disabled) {
    return
  }
  isOpen.value = !isOpen.value
}

const dropdown = ref(null)

function closeDropdown() {
  isOpen.value = false
}

onClickOutside(dropdown, () => {
  closeDropdown()
})

function selectOption(value) {
  selectedOption.value = value
  isOpen.value = false
}

const emit = defineEmits(['update:value'])

const selectedOption = ref()
initSelectedOption()

watch([() => selectedOption.value], () => {
  emit('update:value', selectedOption.value)
})

function initSelectedOption() {
  selectedOption.value = props.initialValue ?? props.items[0]
}

watch([() => props.initialValue], () => {
  initSelectedOption()
})
</script>

<style lang="scss" scoped>
.dropdown {
  display: flex;
  user-select: none;
  position: relative;
  background: #fff;
  color: #344054;
  align-self: flex-start;

  .disabled {
    color: #b8b8b8;
    background: #f6f6f6;
    user-select: none;
    border: 1px solid #f6f6f6;
  }

  .dropdown--open {
    width: 500px;
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #344054;
    font-size: 14px;
    display: inline-block;
    font-weight: 500;
    overflow: visible;
    background: #fff;
    z-index: 2;
    display: flex;
    flex-direction: column;

    .dropdown__main {
      padding: 8px 8px 0 8px;
      .dropdown__filter {
        display: flex;
        position: relative;
        margin-bottom: 8px;

        .filter-input {
          display: flex;
          padding: 8px 10px 8px 34px;
          box-sizing: border-box;
          width: 100%;

          font-size: 14px;
          background-color: white;
          transition: border-color 0.3s;

          border-radius: 8px;
          border: 1px solid #dbdbdb;
          color: #474747;
          font-weight: 500;

          &:focus {
            border: 1px solid #0070ff;
            outline: 2px solid #cbe2ff;
          }

          &::placeholder {
            color: #b9b9b9;
          }
        }
        .search-icon {
          position: absolute;
          top: 50%;
          margin-top: -0.5rem;
          margin-left: 8px;
          stroke: #828282;
        }
      }
      .dropdown__scroll-section {
        display: flex;
        flex-direction: column;
        max-height: 300px;
        overflow: scroll;
      }
      .dropdown__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: 0.05s ease;
        padding: 4px 8px 4px 0;
        min-height: 30px;

        &:hover {
          border-radius: 4px;
          background: rgba(0, 0, 0, 0.07);
          opacity: 1;
          cursor: pointer;
        }
        .dropdown__item-group {
          display: flex;
          align-items: center;
          padding-left: 12px;

          .dropdown__item-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
</style>
