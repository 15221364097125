<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9777 9.11445C16.1164 9.74903 16.1206 10.5473 14.9777 11.2647L6.14688 17.2205C5.0373 17.8126 4.28371 17.463 4.2046 16.1817L4.16713 3.71652C4.14215 2.53629 5.11433 2.20283 6.04071 2.7687L14.9777 9.11445Z"
      stroke-width="1.67"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
