<template>
  <div class="audio-player">
    <audio
      ref="audio"
      @timeupdate="updateProgressBar"
      @ended="audioEnded"
      @loadedmetadata="updateDuration"
    >
      <source :src="audioSource" type="audio/mp3" />
      Your browser does not support the audio element.
    </audio>

    <div class="play-button" @click="togglePlayPause">
      <svg
        v-if="!isPlaying"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="play-03">
          <path
            id="Icon"
            d="M11.2333 6.83584C12.0873 7.31177 12.0905 7.91048 11.2333 8.44855L4.61017 12.9153C3.77799 13.3594 3.21279 13.0972 3.15346 12.1363L3.12535 2.78739C3.10662 1.90222 3.83575 1.65212 4.53054 2.07652L11.2333 6.83584Z"
            stroke="#0070FF"
            stroke-width="1.67"
          />
        </g>
      </svg>
      <svg
        v-else
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.375 12.5V2.5M10.625 12.5V2.5"
          stroke="#0070FF"
          stroke-width="1.67"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>

    <div class="progress-bar">
      <div class="progress" :style="{ width: progress + '%' }"></div>
    </div>

    <div class="current-time">{{ formatDuration(duration) }}</div>
  </div>
</template>

<script>
export default {
  props: {
    audioSource: {
      type: String,
      required: true
    },
    duration: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isPlaying: false,
      progress: 0,
      animationFrameId: null,
      audioDuration: 0
    }
  },

  methods: {
    // updateDuration() {
    //   const audio = this.$refs.audio
    //   this.audioDuration = audio.duration
    // },
    formatDuration(seconds) {
      const minutes = Math.floor(seconds / 60)
      const remainingSeconds = Math.floor(seconds % 60)
      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
    },
    audioEnded() {
      this.isPlaying = false
    },
    togglePlayPause() {
      const audio = this.$refs.audio

      if (audio.paused) {
        audio.play()
        this.isPlaying = true
        this.updateProgressBar() // start the progress bar animation
      } else {
        audio.pause()
        this.isPlaying = false
        this.cancelAnimationFrame()
      }
    },
    updateProgressBar() {
      const audio = this.$refs.audio
      if (!audio) {
        return
      }
      this.animationFrameId = requestAnimationFrame(() => {
        let duration
        if (audio.duration === Infinity) {
          duration = this.duration
        } else {
          duration = audio.duration
        }
        this.progress = (audio.currentTime / duration) * 100
        this.updateProgressBar() // continue the animation
      })
    },
    cancelAnimationFrame() {
      if (this.animationFrameId) {
        cancelAnimationFrame(this.animationFrameId)
      }
    }
  }
}
</script>

<style scoped>
.play-button {
  display: flex;
  margin-right: 5px;
}
.play-button:hover {
  cursor: pointer;
}
audio {
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.audio-player {
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 14px;
  border: 1px solid #dbdbdb;
  padding: 4px 8px;
}

.progress-bar {
  width: 126px;
  height: 4px;
  position: relative;
  border-radius: 2px;
  background: #f1f1f1;
  margin-right: 7px;
}

.progress {
  height: 100%;
  border-radius: 2px;
  background-color: #0070ff;
}

.current-time {
  color: #787878;
  font-size: 10px;
  font-weight: 500;
}

button {
  margin-top: 10px;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
}
</style>
