<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.75 3.71875C1.75 2.63144 2.63144 1.75 3.71875 1.75H10.2812C11.3686 1.75 12.25 2.63144 12.25 3.71875V10.2812C12.25 11.3686 11.3686 12.25 10.2812 12.25H3.71875C2.63144 12.25 1.75 11.3686 1.75 10.2812V3.71875Z"
      stroke-width="1.67"
      stroke-linejoin="round"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 14 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
