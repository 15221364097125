import { sortByDescendingDate } from '@/helpers/date'
import axiosInstance from './axiosService'

export async function fetchActivities(contact) {
  console.log('fetchActivities')
  contact.activityLoading = true
  try {
    const { data } = await axiosInstance.get(`/api/prospects/${contact.id}/activities`)

    contact.notes = sortByDescendingDate(
      data.activities.filter((activity) => activity.type === 'note')
    )
    contact.tasks = sortByDescendingDate(
      data.activities.filter((activity) => activity.type === 'task')
    )
    const onGoingCallLog = contact.callLogs.filter((item) => item.onGoingCall)
    contact.callLogs = [
      ...onGoingCallLog,
      ...sortByDescendingDate(data.activities.filter((activity) => activity.type === 'call'))
    ]
    contact.emails = sortByDescendingDate(
      data.activities.filter((activity) => activity.type === 'email')
    )
  } catch (error) {
    console.error('Error fetching activities:', error)
    // Handle error, e.g., show an error message to the user
  } finally {
    contact.activityLoading = false
  }
}
