import { sortByDescendingDate } from '@/helpers/date'
import axiosInstance from './axiosService'

export async function fetchCallLogs(contact) {
  console.log('fetchCallLogs')
  contact.callLogsLoading = true
  try {
    const { data } = await axiosInstance.get(`/api/prospects/${contact.id}/call-logs`)

    contact.callLogs = sortByDescendingDate(data.calls)
  } catch (error) {
    console.error('Error fetching call logs:', error)
    // Handle error, e.g., show an error message to the user
  } finally {
    contact.callLogsLoading = false
  }
}
