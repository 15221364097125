<template>
  <transition name="side-panel">
    <div v-if="isMenuOpen" class="side-menu">
      <div>
        <div class="fold-menu-button-container">
          <div @click="toggleMenu" class="fold-menu-button">
            <fold-menu-icon class="fold-menu-icon" size="24" />
          </div>
        </div>
        <div class="user-menu" ref="userDetailMenu">
          <div class="menu__item" @click="toggleUserMenu">
            <UserIcon size="18" class="user-icon" />
            {{ authStore.userName }}
          </div>
          <div v-if="isUserMenuOpen" class="user-menu__details">
            <div class="user-menu__email">{{ authStore.userEmail }}</div>
            <div class="user-menu__log-out" @click="logOut">Log out</div>
          </div>
        </div>
        <div class="navigation-menu">
          <router-link to="/">
            <div class="menu__item" :class="{ 'menu__item--active': isActive('/') }">
              <PhoneIcon size="18" class="user-icon" />
              Dialer
            </div>
          </router-link>
          <router-link to="/sessions">
            <div class="menu__item" :class="{ 'menu__item--active': isActive('/sessions') }">
              <FolderIcon size="18" class="user-icon" />
              Sessions
            </div>
          </router-link>
          <router-link to="/analytics">
            <div class="menu__item" :class="{ 'menu__item--active': isActive('/analytics') }">
              <AnalyticsIcon size="18" class="user-icon" />
              Analytics
            </div>
          </router-link>
          <router-link to="/call-history">
            <div class="menu__item" :class="{ 'menu__item--active': isActive('/call-history') }">
              <HistoryIcon size="18" class="user-icon" />
              Call history
            </div>
          </router-link>
        </div>
      </div>
      <div>
        <div class="menu__item" @click="openModal">
          <SettingIcon size="18" class="setting-icon" />
          <div>Settings</div>
        </div>

        <div v-if="authStore.userCompanyId === 1" class="admin-section">
          <div class="admin-section__title">Back-office</div>
          <router-link to="/back-office/analytics">
            <div
              class="menu__item"
              :class="{ 'menu__item--active': isActive('/back-office/analytics') }"
            >
              <AnalyticsIcon size="18" class="user-icon" />
              Analytics
            </div>
          </router-link>
          <router-link to="/back-office/users">
            <div
              class="menu__item"
              :class="{ 'menu__item--active': isActive('/back-office/users') }"
            >
              <UsersIcon size="18" class="user-icon" />
              Users
            </div>
          </router-link>
          <router-link to="/back-office/invitations">
            <div
              class="menu__item"
              :class="{ 'menu__item--active': isActive('/back-office/invitations') }"
            >
              <EmailIcon size="18" class="user-icon" />
              Invitations
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </transition>
  <transition name="menu-button">
    <div v-if="!isMenuOpen" class="menu-button-container">
      <div @click="toggleMenu" class="menu-icon-button">
        <menu-icon class="menu-icon" size="24" />
      </div>
    </div>
  </transition>
  <the-setting-modal :is-modal-open="settingOpen" @close-modal="closeModal" />
</template>

<script setup>
import SettingIcon from './icons/SettingIcon.vue'
import HistoryIcon from './icons/HistoryIcon.vue'
import UserIcon from './icons/UserIcon.vue'
import EmailIcon from './icons/EmailIcon.vue'
import UsersIcon from './icons/UsersIcon.vue'
import MenuIcon from './icons/MenuIcon.vue'
import PhoneIcon from './icons/PhoneIcon.vue'
import FolderIcon from './icons/FolderIcon.vue'
import FoldMenuIcon from './icons/FoldMenuIcon.vue'
import TheSettingModal from './TheSettingModal.vue'
import { useAuthStore } from '@/stores/auth'
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { useRouter, useRoute } from 'vue-router'
import AnalyticsIcon from './icons/AnalyticsIcon.vue'

const router = useRouter()
const route = useRoute()

const authStore = useAuthStore()

const settingOpen = ref(false)

function isActive(path) {
  if (path === '/') {
    return route.path === path
  }
  return route.path.startsWith(path)
}

const props = defineProps({
  isMenuOpen: {
    type: Boolean,
    required: true
  }
})

const isUserMenuOpen = ref(false)
function toggleUserMenu() {
  isUserMenuOpen.value = !isUserMenuOpen.value
}

const userDetailMenu = ref(null)

onClickOutside(userDetailMenu, () => {
  isUserMenuOpen.value = false
})

async function logOut() {
  await authStore.logOut()

  router.push('/login')
}

function closeModal() {
  settingOpen.value = false
}

function openModal() {
  settingOpen.value = true
}
const emit = defineEmits(['toggleMenu'])
function toggleMenu() {
  emit('toggleMenu')
}
</script>

<style scoped lang="scss">
.admin-section {
  border: 2px solid #4a4a4a;
  border-radius: 4px;
  padding: 8px;
  margin-top: 12px;

  &__title {
    font-weight: 600;
    color: #4a4a4a;
    margin-bottom: 4px;
  }
}
.side-menu {
  width: 190px;
  position: fixed;
  height: 100vh;
  /* border-right: 1px solid grey; */
  box-shadow: rgba(0, 0, 0, 0.025) -1px 0px 0px 0px inset;
  background: #fafafa;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 16px 4px;
  color: rgba(25, 23, 17, 0.6);
  font-weight: 500;
}

.menu__item {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  user-select: none;

  &:hover {
    background: rgba(55, 53, 47, 0.08);
    cursor: pointer;
    transition: background 0.02s ease;
  }

  &--active {
    background: rgba(55, 53, 47, 0.08);
    color: rgb(55, 53, 47);
    font-weight: 600;

    .user-icon {
      margin-right: 7px;
      stroke: rgba(55, 53, 47, 1);
    }

    &:hover {
      background: rgba(55, 53, 47, 0.16);
    }
  }
}

.user-menu {
  position: relative;
  margin-top: 16px;

  &__details {
    position: absolute;
    top: calc(100% + 2px);
    left: 6px;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #344054;
    background: #fff;
    z-index: 2;
    min-width: 200px;
    padding: 4px;
  }

  &__email {
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 500;
    color: #667085;
  }

  &__log-out {
    user-select: none;
    transition: background 0.02s ease;
    padding: 4px 8px;
    margin-top: 4px;
    color: rgba(25, 23, 17, 0.6);

    &:hover {
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.07);
      opacity: 1;
      cursor: pointer;
    }
  }
}

.navigation-menu {
  margin-top: 24px;
}

.setting-icon {
  margin-right: 7px;
  stroke: rgba(55, 53, 47, 0.45);
}

.user-icon {
  margin-right: 7px;
  stroke: rgba(55, 53, 47, 0.45);
}

.side-panel-enter-active,
.side-panel-leave-active {
  transition: transform 0.27s ease;
}
.side-panel-enter-from,
.side-panel-leave-to {
  transform: translateX(-100%);
}

.menu-button-enter-active {
  transition: opacity 0.27s ease 0.27s;
}
.menu-button-leave-active {
  transition: transform 0.27s ease;
}
.menu-button-leave-to {
  transform: translateX(190px);
}
.menu-button-enter-from {
  opacity: 0;
}

.fold-menu-icon,
.menu-icon {
  stroke: rgba(55, 53, 47, 0.45);
}
.menu-icon-button {
  border-radius: 4px;
  padding: 2px;
  display: flex;
  align-items: center;
  margin: 16px;
}
.menu-icon-button:hover {
  cursor: pointer;
  background: rgba(55, 53, 47, 0.08);
  transition: background 0.02s ease;
}
.fold-menu-button {
  border-radius: 4px;
  padding: 2px;
  display: flex;
  align-items: center;
  margin-right: 8px;
}
.fold-menu-button:hover {
  cursor: pointer;
  background: rgba(55, 53, 47, 0.08);
  transition: background 0.02s ease;
}
.fold-menu-button-container {
  display: flex;
  justify-content: flex-end;
}
.menu-button-container {
  position: fixed;
}
</style>
