<template>
  <div v-if="heatmapMatrix" class="connect-rate-heatmap">
    <table>
      <thead>
        <tr>
          <th></th>
          <th
            v-for="hour in hours"
            :key="hour"
            :class="{ highlightColumn: hoveredColumn === hour }"
          >
            {{ hour }}h
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(day, dayIndex) in daysOfWeek" :key="dayIndex">
          <td class="day-header" :class="{ highlightRow: hoveredRow === dayIndex }">{{ day }}</td>
          <td
            v-for="hour in hours"
            :key="hour"
            :style="{
              backgroundColor: heatmapMatrix[dayIndex][hour]?.cellBackgroundColor,
              color: getTextColor(heatmapMatrix[dayIndex][hour]?.connectRate)
            }"
            @mouseenter="highlight(dayIndex, hour)"
            @mouseleave="clearHighlight"
          >
            <template v-if="heatmapMatrix[dayIndex][hour]">
              <span v-tooltip.top="getTooltipContent(heatmapMatrix[dayIndex][hour])">
                {{ heatmapMatrix[dayIndex][hour].connectRate.toFixed(2) + '%' }}
              </span>
            </template>
            <template v-else>
              {{ '-' }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'

const heatmapMatrix = ref(null)

const props = defineProps({
  heatmapData: {
    type: Array,
    required: true
  }
})

watch(
  () => props.heatmapData,
  () => {
    maxConnectRate.value = 0
    // Initialize the heatmap matrix as 7 days x 24 hours (all null initially)
    const matrix = Array(7)
      .fill(0)
      .map(() => Array(24).fill(null))

    // Fill the heatmap matrix with connect rates
    props.heatmapData.forEach((dataPoint) => {
      const { day_of_week, hour_of_day, connect_rate, total_calls } = dataPoint
      matrix[day_of_week][hour_of_day] = {
        connectRate: connect_rate,
        totalCalls: total_calls
      }

      if (connect_rate > maxConnectRate.value) {
        maxConnectRate.value = connect_rate
      }
    })

    matrix.forEach((day, dayIndex) => {
      day.forEach((hour, hourIndex) => {
        if (hour) {
          matrix[dayIndex][hourIndex].cellBackgroundColor = getCellColor(hour.connectRate)
        }
      })
    })

    // Assign to the ref
    heatmapMatrix.value = matrix
  }
)

const getTooltipContent = (cellData) => {
  const callText = cellData.totalCalls === 1 ? 'call' : 'calls'
  return `Total ${callText}: ${cellData.totalCalls}`
}

const maxConnectRate = ref(0)

// Days of the week labels
const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']

// Hours of the day from 0 to 23
// const hours = Array.from({ length: 24 }, (_, i) => i)
const hours = Array.from({ length: 15 }, (_, i) => i + 6) // Generates [6, 7, 8, ..., 20]

// Function to get background color for each cell based on the connect rate
const getCellColor = (connectRate) => {
  if (connectRate === null) return '#ffffff' // White for empty cells
  const maxRate = maxConnectRate.value // Assuming 100% is the max connect rate

  // Custom color reference (rgb(0, 83, 94))
  const rBase = 0
  const gBase = 83
  const bBase = 94

  const intensity = Math.floor((connectRate / maxRate) * 255) // Calculate intensity based on connect rate

  // Calculate new color by transitioning between white and the reference color
  const r = 255 - ((255 - rBase) * intensity) / 255
  const g = 255 - ((255 - gBase) * intensity) / 255
  const b = 255 - ((255 - bBase) * intensity) / 255

  return `rgb(${r}, ${g}, ${b})` // Return the calculated color
}

// Function to get text color (use darker text for lighter backgrounds and white for dark ones)
const getTextColor = (connectRate) => {
  if (connectRate === null || connectRate === undefined) return '#667085' // Black text for empty cells
  const colorIntensity = Math.floor((connectRate / maxConnectRate.value) * 255)
  return colorIntensity < 60 ? '#667085' : '#fff' // Black text for lighter blue, white for darker blue
}

const hoveredRow = ref(null)
const hoveredColumn = ref(null)

// Highlight the row and column on hover
const highlight = (row, column) => {
  hoveredRow.value = row
  hoveredColumn.value = column
}

// Clear the highlight when no longer hovering
const clearHighlight = () => {
  hoveredRow.value = null
  hoveredColumn.value = null
}
</script>

<style scoped lang="scss">
.connect-rate-heatmap {
  border: 1px solid #eaecf0;
  border-radius: 8px;
  overflow: scroll;
}

table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}

th,
td {
  padding: 10px;
}

td > span {
  display: block;
  margin: -10px;
  padding: 10px;
}

th {
  background-color: #fcfcfd;
  color: #667085;
  font-weight: 500;
  font-size: 12px;
  user-select: none;
  border-right: 1px solid #eaecf0;
  border-bottom: 1px solid #eaecf0;

  &:last-child {
    border-radius: 0 8px 0 0;
    border-right: none;
  }

  &:first-child {
    border-radius: 8px 0 0 0;
  }
}

.day-header {
  background-color: #fcfcfd;
  color: #667085;
  font-weight: 500;
  font-size: 12px;
  user-select: none;
  border-right: 1px solid #eaecf0;
  border-bottom: 1px solid #eaecf0;
}

tr:last-child .day-header {
  border-bottom: none;
  border-radius: 0 0 0 8px;
}

tr:last-child td:last-child {
  border-radius: 0 0 8px 0;
}

td {
  width: 40px;
  height: 40px;
}

.highlightRow,
.highlightColumn {
  background-color: rgba(55, 53, 47, 0.08);
}
</style>
