// stores/customFields.js
import { defineStore } from 'pinia'
import { useAuthStore } from './auth'
import axiosInstance from '@/services/axiosService'
import { usePhoneStore } from './phone'

export const useCustomFieldsStore = defineStore('customFields', {
  state: () => ({
    customFields: [],
    loading: false,
    error: null,
    resyncLoading: null
  }),

  getters: {
    activeCustomFields: (state) => state.customFields.filter((field) => field.active),
    inactiveCustomFields: (state) => state.customFields.filter((field) => !field.active)
  },

  actions: {
    async fetchCustomFields() {
      this.loading = true
      this.error = null
      try {
        const response = await axiosInstance.get(`/api/companies/custom-fields`)
        this.customFields = response.data
      } catch (err) {
        this.error = err.response?.data?.error || 'An error occurred while fetching custom fields.'
      } finally {
        this.loading = false
      }
    },

    async addCustomField({ name, label, type, options, importSource }) {
      this.loading = true
      this.error = null
      try {
        await axiosInstance.post(`/api/companies/custom-fields/add`, {
          name,
          label,
          options,
          type,
          importSource
        })
        await this.fetchCustomFields()
      } catch (err) {
        const phoneStore = usePhoneStore()
        phoneStore.errorMessage =
          err.response?.data?.error || 'An error occurred while adding the custom field.'
        this.error = err.response?.data?.error || 'An error occurred while adding the custom field.'
      } finally {
        this.loading = false
      }
    },

    async deleteCustomField({ name, importSource }) {
      this.loading = true
      this.error = null
      try {
        await axiosInstance.post(`/api/companies/custom-fields/delete`, {
          fieldName: name,
          importSource
        })
        await this.fetchCustomFields()
      } catch (err) {
        this.error =
          err.response?.data?.error || 'An error occurred while deleting the custom field.'
      } finally {
        this.loading = false
      }
    },

    async resync({ name, importSource }) {
      this.resyncLoading = true
      this.error = null
      try {
        await axiosInstance.post(`/api/companies/custom-fields/resync`, {
          fieldName: name,
          importSource
        })
        await this.fetchCustomFields()
      } catch (err) {
        console.error(err)
        this.error =
          err.response?.data?.error || 'An error occurred while resyncing the custom field.'
      } finally {
        this.resyncLoading = false
      }
    }
  }
})
