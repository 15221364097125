<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4113 2.26318L17.9979 2.26318M17.9979 2.26318L18 6.31612M17.9979 2.26318L12.7871 7.47405M17.0304 15.8521C17.0304 15.8521 16.0371 16.8277 15.7937 17.1137C15.3972 17.5368 14.93 17.7366 14.3175 17.7366C14.2586 17.7366 14.1958 17.7366 14.1369 17.7327C12.9709 17.6583 11.8873 17.2038 11.0746 16.8159C8.85251 15.7424 6.90128 14.2183 5.27983 12.2867C3.94106 10.6764 3.04593 9.18756 2.4531 7.58902C2.08798 6.61344 1.95449 5.85335 2.01338 5.13635C2.05264 4.67795 2.22931 4.2979 2.55517 3.97271L3.89395 2.63667C4.08632 2.45644 4.29047 2.35849 4.4907 2.35849C4.73804 2.35849 4.93827 2.50738 5.0639 2.63275C5.06783 2.63667 5.07175 2.64059 5.07568 2.64451C5.31516 2.86783 5.54287 3.099 5.78236 3.34583C5.90407 3.47121 6.0297 3.59658 6.15533 3.72587L7.22714 4.79549C7.64329 5.2108 7.64329 5.59476 7.22714 6.01007C7.11328 6.12369 7.00335 6.23731 6.8895 6.34701C6.55971 6.68396 6.81878 6.42542 6.47721 6.73103C6.46936 6.73886 6.46151 6.74278 6.45758 6.75062C6.11995 7.08757 6.18276 7.41668 6.25343 7.64C6.25736 7.65176 6.26128 7.66351 6.26521 7.67527C6.54396 8.34916 6.93656 8.98388 7.53331 9.74005L7.53724 9.74397C8.62082 11.0761 9.76329 12.1144 11.0235 12.9097C11.1845 13.0116 11.3494 13.0939 11.5064 13.1722C11.6478 13.2427 11.7813 13.3093 11.8951 13.3799C11.9108 13.3877 11.9265 13.3995 11.9422 13.4073C12.0757 13.4739 12.2013 13.5052 12.3309 13.5052C12.6568 13.5052 12.8609 13.3015 12.9277 13.2349L13.6972 12.4669C13.8307 12.3337 14.0427 12.1731 14.29 12.1731C14.5335 12.1731 14.7337 12.3259 14.8554 12.4591C14.8593 12.463 14.8593 12.463 14.8632 12.4669L17.0265 14.6257C17.4309 15.0254 17.0304 15.8521 17.0304 15.8521Z"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
