<template>
  <div class="add-contacts">
    <!-- <Transition>
      <div class="overlay" v-if="showDropdown"></div>
    </Transition> -->

    <div class="add-contacts-modal-group" ref="addContactItem">
      <Loader
        v-if="phoneStore.addContactLoading"
        class="loader"
        :size="20"
        :borderWidth="2"
        border-color="#ababab"
      />
      <div
        v-else
        class="add-contacts__button"
        @click="openModal"
        :class="{ 'no-hover': showDropdown }"
        :style="{
          'z-index': showDropdown ? 4 : 1
        }"
      >
        <div class="plus-icon">
          <PlusIcon color="#667085" size="20" />
        </div>
        <div>Add list</div>
      </div>

      <div v-if="showDropdown" class="add-contacts-modal">
        <div class="import-list__input-group">
          <div class="search-input">
            <input
              type="text"
              ref="inputField"
              v-model="searchTerm"
              @focus="openDropdown"
              @click="openDropdown"
              placeholder="Search list..."
              class="input__text"
              @keydown.down.prevent="focusNextItem"
              @keydown.up.prevent="focusPreviousItem"
              @keydown.enter.prevent="selectHighlightedItem"
              @keydown.esc.prevent="closeDropdown"
              :aria-expanded="showDropdown"
              aria-controls="importListDropdown"
              aria-label="Search list..."
              :disabled="addContactLoading"
            />
            <span class="input__icon" @click="focusInput">
              <i class="pi pi-search"></i>
            </span>
          </div>
          <Loader
            v-if="addContactLoading"
            class="input__loader"
            :size="20"
            :borderWidth="2"
            border-color="#ababab"
          />
          <div
            v-if="showDropdown"
            class="import-list__dropdown-menu"
            ref="importListDropdown"
            role="listbox"
          >
            <div v-if="listLoading" class="empty-state">
              <Loader :size="20" :borderWidth="2" border-color="#ababab" />
            </div>
            <div v-else-if="filteredList.length === 0" class="empty-state">No list</div>
            <div
              v-for="(item, index) in filteredList"
              :key="item.id"
              @click="selectValue(item)"
              @mouseenter="highlightedIndex = index"
              :class="[
                'dropdown-item',
                { 'dropdown-item--highlighted': highlightedIndex === index }
              ]"
              role="option"
              :aria-selected="highlightedIndex === index"
            >
              <div>
                <img
                  class="item__logo"
                  :src="item.logo"
                  :alt="'item.logo' + 'logo'"
                  rel="preload"
                />
              </div>

              <div v-if="item.type === 'list'" class="item-list__text">
                <div>
                  {{ item.text }}
                </div>
                <div class="item-list__count">
                  {{ item.count }} {{ item.count > 1 ? 'contacts' : 'contact' }}
                </div>
              </div>
              <div v-else-if="item.type === 'task'" class="item-list__text">
                <div class="item-list__task-text">
                  <ListIcon class="item__task-icon" />{{ item.text }}
                </div>
                <!-- <div class="item-list__count">
                  {{ item.count }} {{ item.count > 1 ? 'tasks' : 'task' }}
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ListIcon from './icons/ListIcon.vue'
import Loader from './Loader.vue'
import PlusIcon from './icons/PlusIcon.vue'
import { onClickOutside } from '@vueuse/core'
import { nextTick, ref } from 'vue'

const emit = defineEmits(['import-list', 'close'])

import { useImport } from '@/composables/useImport'
import { usePhoneStore } from '@/stores/phone'

const phoneStore = usePhoneStore()

const {
  searchTerm,
  showDropdown,
  inputField,
  focusInput,
  filteredList,
  selectValue,
  importListDropdown,
  closeDropdown,
  highlightedIndex,
  focusNextItem,
  focusPreviousItem,
  selectHighlightedItem,
  scrollToHighlightedItem,
  importLists,
  openDropdown,
  listLoading
} = useImport(emit)

function openModal() {
  if (!showDropdown.value) {
    console.log('ici?')
    openDropdown()
    nextTick(() => focusInput())
  }
}

const addContactItem = ref(null)

onClickOutside(addContactItem, () => {
  closeDropdown()
})
</script>

<style lang="scss" scoped>
.add-contacts {
  .add-contacts-modal-group {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: rgba(15, 15, 15, 0.6);
  }

  .add-contacts__button {
    position: inherit;
    background: #ffffff;
    color: #667085;
    border-radius: 4px;
    font-weight: 600;
    padding: 2px 4px;
    border: 1px solid rgba(55, 53, 47, 0.16);
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 12px;
    // transition: 0.05s ease;
    user-select: none;
    display: flex;
    &:hover {
      background: rgba(0, 0, 0, 0.07);
      opacity: 1;
      cursor: pointer;
    }

    .plus-icon {
      display: flex;
      align-items: center;
    }
  }
  .no-hover:hover {
    background: #ffffff;
  }

  .add-contacts-modal {
    position: absolute;
    overflow: hidden;
    z-index: 4;
    top: calc(100% + 4px);
    left: 0px;

    box-shadow:
      rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
      rgba(15, 15, 15, 0.1) 0px 5px 10px,
      rgba(15, 15, 15, 0.2) 0px 15px 40px;
    border-radius: 12px;
    background-color: #ffffff;

    .search-input {
      position: relative;
      border-bottom: 1px solid #eaecf0;
      padding: 6px 12px;
    }
  }
}
.import-list__input-group {
  display: flex;
  flex-direction: column;
  width: 400px;

  line-height: normal;

  .input__icon {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translate(0, -50%);
    color: #c2c2c2;
  }
  .input__text {
    color: #334155;
    padding: 0.5rem 0.75rem 0.5rem calc(20px + 0.75rem);
    border: none;
    outline: none;
    width: 100%;

    box-sizing: border-box;
    transition: border-color 0.3s;
    font-weight: 500;
  }
  input::placeholder {
    font-weight: 500;
  }
  .import-list__dropdown-menu {
    padding-bottom: 12px;
    color: #344054;
    font-size: 14px;
    display: inline-block;
    font-weight: 500;
    overflow: visible;
    max-height: 400px;
    overflow-y: auto;
    .dropdown-item {
      padding: 8px;
      cursor: pointer;

      border-bottom: 1px solid #eaecf0;
      display: flex;
      align-items: center;
      padding: 16px 0 16px 24px;
      transition: background 0.027s ease;
      color: #2b2c2e;

      .item__logo {
        width: 40px;
        margin-right: 20px;
      }

      .item__text {
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        line-height: 14px;
        flex: 1;
      }

      .item-list__text {
        font-size: 14px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        line-height: 14px;
        flex: 1;
        .item-list__task-text {
          display: flex;
        }
      }

      .item-list__count {
        color: #667085;
        margin-top: 8px;
        font-weight: 600;
        font-size: 12px;
      }

      .item__task-icon {
        margin-right: 5px;
        stroke: #2b2c2e;
      }
    }
    .dropdown-item--highlighted {
      background-color: #f0f0f0;
    }
  }
  .input__loader {
    position: absolute;
    right: 10px;
    top: 7px;
  }
  .empty-state {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.02s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
