<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.14651 3.30336C8.66089 2.78898 9.35855 2.5 10.086 2.5C10.8135 2.5 11.5111 2.78898 12.0255 3.30336C12.5399 3.81775 12.8289 4.51541 12.8289 5.24286V8M3.75 9.81428C3.97152 11.3364 4.73365 12.7278 5.89696 13.7341C7.06026 14.7403 8.54696 15.2941 10.0851 15.2941M10.0851 15.2941C11.6232 15.2941 13.1099 14.7403 14.2732 13.7341C15.4365 12.7278 16.1986 11.3364 16.4202 9.81428M10.0851 15.2941V18.5M7.34314 7V8.9C7.34314 9.62745 7.63212 10.3251 8.14651 10.8395C8.66089 11.3539 9.35855 11.6429 10.086 11.6429C10.5514 11.6429 11.0046 11.5246 11.4055 11.3046M3.25 2.5L17.75 17"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 21 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
