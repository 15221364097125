<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.27273 13.7273L1 21M21 7.66636L14.3336 1L8.81 6.8C8.64016 6.97816 8.43592 7.11998 8.20964 7.21687C7.98337 7.31375 7.73978 7.36368 7.49364 7.36364H4.10364C3.29455 7.36364 2.88818 8.34273 3.46091 8.91545L13.0845 18.5391C13.6573 19.1118 14.6364 18.7064 14.6364 17.8964V14.5064C14.6363 14.2602 14.6862 14.0166 14.7831 13.7904C14.88 13.5641 15.0218 13.3598 15.2 13.19L21 7.66636Z"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.67"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
