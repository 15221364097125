import { defineStore } from 'pinia'
import { fetchUserVoicemails } from '../services/voicemailService'

export const useVoicemailStore = defineStore('voicemails', {
  state: () => ({
    voicemailSelected: null,
    audioFiles: []
  }),
  actions: {
    reset() {
      this.$reset()
    },
    setVoicemail(voicemail) {
      this.voicemailSelected = voicemail
    },
    async fetchUserVoicemails() {
      const result = await fetchUserVoicemails()
      this.audioFiles = result
    }
  }
})
