<script setup>
import CallIcon from './icons/CallIcon.vue'
import { useContactStore } from '@/stores/contactStore'

const contactStore = useContactStore()
</script>

<template>
  <div class="call-one-contact" @click="contactStore.openSearchContact()">
    <CallIcon size="18" class="call-icon" />
  </div>
</template>

<style lang="scss">
.call-one-contact {
  border: 1px solid #d0d5dd;
  padding: 8px;
  display: flex;
  align-content: center;
  border-radius: 8px;
  align-self: flex-end;

  &:hover {
    cursor: pointer;
    border: 1px solid #c2c2c2;
  }

  .call-icon {
    stroke: #787878;
  }
}
</style>
