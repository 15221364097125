<template>
  <div :class="{ container: callLogs.length === 0 }">
    <div class="loader-section" v-if="callLogs.length === 0 && loading">
      <Loader></Loader>
    </div>
    <div class="empty-state" v-else-if="callLogs.length === 0">There is no call log yet</div>
    <div class="log__section-body" v-if="pinnedCallLog">
      <prospect-call-log
        :call-log="pinnedCallLog"
        :prospect-name="prospectName"
        @save-call-disposition="saveCallDisposition"
        @save-log="saveLog"
      />
    </div>
    <div v-for="log in regularCallLogs" :key="log.id" class="log__section-body">
      <prospect-call-log
        :callLog="log"
        :prospect-name="prospectName"
        @save-call-disposition="saveCallDisposition"
        @save-log="saveLog"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import Loader from './Loader.vue'
import ProspectCallLog from './ProspectCallLog.vue'

const props = defineProps({
  callLogs: {
    type: Object,
    required: true
  },
  loading: {
    type: Boolean,
    required: true
  },
  prospectName: {
    required: true,
    type: String
  }
})

const pinnedCallLog = computed(() => props.callLogs.find((callLog) => callLog.pinned))
const regularCallLogs = computed(() => props.callLogs.filter((callLog) => !callLog.pinned))

const emit = defineEmits(['saveLog', 'saveCallDisposition'])

function saveCallDisposition(logId, callDisposition) {
  emit('saveCallDisposition', logId, callDisposition)
}
function saveLog(logId, callBody) {
  emit('saveLog', logId, callBody)
}
</script>

<style scoped lang="scss">
.loader-section {
  display: flex;
  justify-content: center;
}
.container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.log__section-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
</style>
