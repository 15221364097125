<template>
  <transition appear>
    <div class="notification">
      <div class="notification__caller">
        <div class="notification__green-dot"></div>
        <div :class="{ 'notification__caller-link': externalLinkActivated }">
          <div class="notification__caller-group">
            <div class="notification__caller-name">
              {{ name }}
            </div>
            <external-link-icon
              v-if="externalLinkActivated"
              class="external-link-icon"
            ></external-link-icon>
          </div>
          <div class="notification__phone-number">{{ phoneNumber }}</div>
          <div v-if="typeof callDuration === 'number'" class="notification__duration">
            {{ formatDuration(callDuration) }}
          </div>
        </div>
      </div>
      <div class="notification__actions">
        <div
          @click="$emit('muteCall')"
          class="notification__mute-button"
          :class="{
            'notification__mute-button--off': !isMuted,
            'notification__mute-button--on': isMuted
          }"
        >
          <mute-icon />
        </div>
        <div @click="$emit('endCall')" class="notification__hang-up-button">
          <HangUpIcon class="hang-up-icon" />
        </div>
        <div
          v-if="isCallingInSession"
          @click="$emit('hangupAndResume')"
          class="notification__resume-button"
        >
          <HangUpIcon class="hang-up-icon" />
          <ChevronNextIcon class="resume-icon" />
        </div>
        <div class="resume-tooltip">
          <div class="tooltip__title">Hang up & dial next</div>
          <div class="tooltip__subtitle">⌘+P</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import ExternalLinkIcon from './icons/ExternalLinkIcon.vue'
import HangUpIcon from './icons/HangUpIcon.vue'
import MuteIcon from './icons/MuteIcon.vue'
import ChevronNextIcon from './icons/ChevronNextIcon.vue'
import PhoneIcon from './icons/PhoneIcon.vue'

import { ref, onMounted, onUnmounted } from 'vue'

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  phoneNumber: {
    type: String,
    required: true
  },
  isMuted: {
    type: Boolean,
    default: false
  },
  isCallingInSession: {
    type: Boolean,
    required: true
  }
})

const externalLinkActivated = false

function formatDuration(seconds) {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = Math.floor(seconds % 60)
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
}

const callDuration = ref(null)
let timerInterval = null

onMounted(() => {
  startTimer()
})

onUnmounted(() => {
  clearTimer()
})

function clearTimer() {
  clearInterval(timerInterval)
}

function startTimer() {
  callDuration.value = 0
  timerInterval = setInterval(() => {
    callDuration.value += 1
  }, 1000)
}
</script>

<style scoped lang="scss">
.notification {
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  padding: 18px 28px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
  opacity: 1;
  margin-top: 10px;
}
.notification__caller-group {
  display: flex;
  align-items: center;
}
.notification__caller {
  display: flex;
  align-items: center;
  width: 222px;
}
.notification__green-dot {
  background: #1dde84;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin-right: 16px;
}

.notification__caller-name {
  font-size: 16px;
  font-weight: 500;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.notification__phone-number {
  font-size: 14px;
}
.notification__duration {
  font-size: 14px;
  color: #b8b8b8;
  width: 40px;
}
.notification__actions {
  display: flex;
  align-items: center;
  position: relative;
}
.notification__duration,
.notification__mute-button {
  margin-right: 12px;
}
.notification__mute-button {
  border-radius: 50%;
  padding: 12px;
  display: flex;
  margin-left: 12px;
}
.notification__mute-button:hover {
  cursor: pointer;
}
.notification__mute-button--on {
  background: #fff;
  stroke: #4a4a4a;
}
.notification__mute-button--off {
  background: #4a4a4a;
  stroke: #fff;
}
.notification__mute-button--on:hover {
  background: rgba(250, 250, 250, 0.95);
}
.notification__mute-button--off:hover {
  background: rgba(74, 74, 74, 0.8);
}
.notification__hang-up-button {
  background: #f24646;
  border-radius: 50%;
  padding: 12px;
  display: flex;
  margin-right: 12px;
}

.notification__resume-button {
  background: #0070ff;
  border-radius: 100px;
  padding: 12px;
  display: flex;
  &:hover {
    cursor: pointer;
    background: #006fe6;
    ~ .resume-tooltip {
      opacity: 1;
    }
  }
}

.resume-tooltip {
  opacity: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #363636;
  border-radius: 8px;
  padding: 4px 16px;
  transition: opacity 0.02s ease;

  .tooltip__subtitle {
    color: #9f9f9f;
  }

  top: -50px;
  right: 0;
}

.notification__hang-up-button:hover {
  cursor: pointer;
  background: #ea3a3a;
}

.v-enter-active,
.v-leave-active {
  transition:
    opacity 0.27s ease,
    transform 0.27s ease;
}

.v-enter-from,
.v-leave-to {
  transform: translateX(180px);
  opacity: 0;
}
.notification__caller-link:hover {
  cursor: pointer;
}

.external-link-icon {
  stroke: #fff;
}

.hang-up-icon {
  stroke: #fff;
  fill: #fff;
}

.resume-icon {
  stroke: #fff;
  margin-left: 8px;
}
</style>
