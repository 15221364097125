import { assertIsPhoneNumberValid } from '@/helpers/phoneNumber'
import axiosInstance from './axiosService'

export async function updatePhoneNumberField(contact, phoneNumberField, value) {
  await axiosInstance.post(`/api/prospects/${contact.id}/update-phone-number`, {
    field: phoneNumberField,
    value
  })
  contact.phoneNumber = value
  try {
    assertIsPhoneNumberValid(value)
    contact.wrongNumber = false
  } catch (error) {
    console.error(`invalid phone number`, error)
    contact.wrongNumber = true
  }

  console.log('Field updated')
}
