<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6763 3.26762C11.2488 1.51073 8.75121 1.51073 8.3237 3.26762C8.25987 3.53163 8.13468 3.77681 7.95831 3.98323C7.78194 4.18965 7.55938 4.35147 7.30874 4.45552C7.0581 4.55957 6.78646 4.60291 6.51592 4.58202C6.24538 4.56112 5.9836 4.47658 5.75187 4.33528C4.20832 3.39376 2.44201 5.16192 3.38254 6.70709C3.99006 7.70491 3.45117 9.00682 2.31713 9.28274C0.560955 9.70958 0.560955 12.2109 2.31713 12.6366C2.58093 12.7006 2.8259 12.826 3.03211 13.0027C3.23831 13.1794 3.39991 13.4023 3.50375 13.6534C3.6076 13.9044 3.65074 14.1765 3.62968 14.4474C3.60862 14.7182 3.52394 14.9803 3.38254 15.2123C2.44201 16.7574 4.20832 18.5256 5.75187 17.5841C5.98356 17.4425 6.24536 17.3578 6.51597 17.3367C6.78658 17.3156 7.05834 17.3588 7.30912 17.4627C7.5599 17.5667 7.7826 17.7285 7.95911 17.9349C8.13561 18.1413 8.26091 18.3865 8.32482 18.6506C8.75121 20.4086 11.2499 20.4086 11.6752 18.6506C11.7393 18.3867 11.8647 18.1416 12.0413 17.9353C12.2178 17.729 12.4405 17.5674 12.6912 17.4635C12.9419 17.3595 13.2135 17.3163 13.4841 17.3373C13.7546 17.3582 14.0164 17.4428 14.2481 17.5841C15.7917 18.5256 17.558 16.7574 16.6175 15.2123C16.4763 14.9803 16.3918 14.7182 16.3709 14.4474C16.35 14.1766 16.3932 13.9046 16.497 13.6537C16.6008 13.4027 16.7623 13.1798 16.9683 13.0031C17.1744 12.8264 17.4192 12.7008 17.6829 12.6366C19.439 12.2098 19.439 9.70845 17.6829 9.28274C17.4191 9.21877 17.1741 9.09333 16.9679 8.91664C16.7617 8.73995 16.6001 8.51701 16.4962 8.26597C16.3924 8.01493 16.3493 7.74289 16.3703 7.47199C16.3914 7.2011 16.4761 6.93902 16.6175 6.70709C17.558 5.16192 15.7917 3.39376 14.2481 4.33528C14.0164 4.47682 13.7546 4.56159 13.484 4.58267C13.2134 4.60376 12.9417 4.56056 12.6909 4.45661C12.4401 4.35266 12.2174 4.19089 12.0409 3.98447C11.8644 3.77805 11.7391 3.53282 11.6752 3.26875L11.6763 3.26762Z"
      stroke-width="1.67"
      vector-effect="non-scaling-stroke"
    />
    <path
      d="M12 10.9595C12 12.0653 11.1046 12.9617 10 12.9617C8.89543 12.9617 8 12.0653 8 10.9595C8 9.85379 8.89543 8.95741 10 8.95741C11.1046 8.95741 12 9.85379 12 10.9595Z"
      stroke-width="1.67"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
svg {
  stroke: #667085;
}
</style>
