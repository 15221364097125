<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.45467 13.3337C7.29562 13.3337 8.788 11.8412 8.788 10.0003C8.788 8.15938 7.29562 6.66699 5.45467 6.66699C3.61372 6.66699 2.12134 8.15938 2.12134 10.0003C2.12134 11.8412 3.61372 13.3337 5.45467 13.3337ZM5.45467 13.3337H15.4547M15.4547 13.3337C17.2956 13.3337 18.788 11.8412 18.788 10.0003C18.788 8.15938 17.2956 6.66699 15.4547 6.66699C13.6138 6.66699 12.1213 8.15938 12.1213 10.0003C12.1213 11.8412 13.6138 13.3337 15.4547 13.3337Z"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
