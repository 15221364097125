import { defineStore } from 'pinia'
import axios from '@/services/axiosService'

import salesforceLogo from '@/assets/salesforce-logo.png'
import hubspotLogo from '@/assets/hubspot-logo.png'
import pipedriveLogo from '@/assets/pipedrive-logo.png'

import { useSalesforceStore } from '@/stores/salesforceStore'
import { useHubspotStore } from '@/stores/hubspotStore'
import { useSessionStore } from './sessionStore'
import { usePhoneStore } from './phone'
import { useVoicemailStore } from './voicemail'
import { usePipedriveStore } from './pipedriveStore'

const logoList = {
  salesforce: salesforceLogo,
  hubspot: hubspotLogo,
  pipedrive: pipedriveLogo
}

export const useListStore = defineStore('listStore', {
  state: () => ({
    importLists: [],
    listLoading: false,
    listsFetched: false
  }),
  actions: {
    async addContacts(importItem) {
      const sessionStore = useSessionStore()
      const phoneStore = usePhoneStore()
      try {
        phoneStore.addContactLoading = true

        const response = await axios.post(
          `/api/hubspot/sessions/${sessionStore.currentSessionId}/add-contacts`,
          {
            listId: importItem.id,
            importSource: importItem.source
          }
        )
        await phoneStore.initContactList()
        phoneStore.successMessage = 'New list added'
        console.log('New list imported')
        console.log('Error saving some leads:', response.data.validationErrors)
      } catch (error) {
        console.error('Error fetching leads:', error)
        console.log('Error saving some leads:', error?.response?.data?.validationErrors)
        if (error?.response?.data?.error === 'no_lead') {
          phoneStore.errorMessage = 'There is no lead in this list'
        } else if (error?.response?.data?.error === 'no_new_lead') {
          phoneStore.successMessage = 'List updated'
        } else {
          phoneStore.errorMessage = error?.response?.data?.error || 'An error occurred'
        }
      } finally {
        phoneStore.addContactLoading = false
      }
    },
    async importNewList(importItem) {
      const sessionStore = useSessionStore()
      const phoneStore = usePhoneStore()
      const voicemailStore = useVoicemailStore()

      if (!sessionStore.fromPhoneNumber) {
        phoneStore.errorMessage = 'You need a phone number to start importing'
        return
      }

      sessionStore.tempSessionName = importItem.text

      if (sessionStore.currentSessionId) {
        await sessionStore.endSession()
        phoneStore.resetSession()
        sessionStore.resetSession()
      }
      phoneStore.sortByField = null
      phoneStore.contactLoading = true

      try {
        phoneStore.emptyMessage = ''
        const response = await axios.post(`/api/${importItem.source}/leads/import`, {
          listId: importItem.id,
          listName: importItem.text,
          fromPhoneNumberId: sessionStore.fromPhoneNumber.id,
          parallelCalls: sessionStore.callInParallel,
          voicemailId: voicemailStore.voicemailSelected?.messageId
        })
        sessionStore.setSessionId(response.data.sessionId)
        await phoneStore.initContactList()
        phoneStore.contactLoading = false
        await sessionStore.initCurrentSessionSetting()
        console.log('New list imported')
        console.log('Error saving some leads:', response.data.validationErrors)
      } catch (error) {
        console.error('Error fetching leads:', error)
        console.log('Error saving some leads:', error?.response?.data?.validationErrors)
        if (error?.response?.data?.error === 'no_lead') {
          phoneStore.emptyMessage = 'There is no lead in this list'
        } else if (error?.response?.data?.error === 'session_already_in_progress') {
          phoneStore.errorMessage = 'A session is already in progress, refresh the page'
        } else {
          phoneStore.errorMessage = error?.response?.data?.error || 'An error occurred'
        }
        phoneStore.contactLoading = false
      }
    },
    async fetchLists() {
      const salesforceStore = useSalesforceStore()
      const hubspotStore = useHubspotStore()
      const pipedriveStore = usePipedriveStore()

      const storeMap = {
        hubspot: hubspotStore,
        salesforce: salesforceStore,
        pipedrive: pipedriveStore
      }

      // Prevent fetching if lists are already being fetched or have been fetched
      if (this.listsFetched || this.listLoading) return

      this.listLoading = true
      try {
        const response = await axios.get('/api/prospects/lists')

        this.importLists = response.data
          .filter((item) => item.source === 'minari' || storeMap[item.source]['isConnected'])
          .map((item) => ({
            ...item,
            logo: logoList[item.source]
          }))

        this.listsFetched = true
      } catch (error) {
        console.error('Error fetching lists:', error)
      } finally {
        this.listLoading = false
      }
    }
  }
})
