<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.7539 10C16.7539 14.1421 13.3952 17.5 9.25195 17.5C5.10874 17.5 1.75 14.1421 1.75 10C1.75 5.85786 5.10874 2.5 9.25195 2.5C12.0287 2.5 14.4531 4.00824 15.7503 6.25M14.6959 10.823L16.5714 8.94805L18.4469 10.823M12.0625 12.0451L9.25 11.1076V7.1875"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
