import axiosInstance from './axiosService'

export async function updateNote(contact, noteId, body) {
  await axiosInstance.post(`/api/prospects/update-note`, {
    noteId,
    body,
    importSource: contact.importSource,
    sourceId: contact.sourceId
  })
  contact.notes.find((note) => note.id === noteId).body = body

  console.log('Note updated')
}
