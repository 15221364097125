<template>
  <div class="session-list-table">
    <div v-if="sessions" class="prime-table">
      <DataTable
        :value="sessions"
        style="width: 100%"
        scrollable
        scrollHeight="flex"
        tableStyle="padding-bottom: 12px"
        @row-click="openSessionDetails"
      >
        <template #empty>
          <div class="empty-message">No session yet</div>
        </template>
        <!-- <Column
        bodyStyle="padding:0;vertical-align: middle;"
        headerClass="header header--checkbox-column"
        bodyClass="cell"
      >
        <template #header>
          <div class="header-icon-placeholder"></div>
        </template>
        <template #body="bodyProps">
          <div class="icon-column">
            <ExternalLinkIcon class="link-icon" size="18" />
            <div
              class="row-menu"
              @click="($event) => rowMenuClicked($event, bodyProps)"
              aria-haspopup="true"
              aria-controls="overlay_menu"
            >
              <ThreeDotIcon size="18" class="three-dot-icon" />
            </div>
          </div>
        </template>
      </Column> -->
        <Column
          field="session_name"
          header="Session name"
          headerClass="data-table-header"
          bodyClass="cell cell-first-column"
          style="min-width: 195px"
        >
        </Column>
        <Column
          header="Dialed"
          headerClass="data-table-header"
          bodyClass="cell"
          style="min-width: 195px"
        >
          <template #body="slotProps">
            <div class="progress-cell">
              <PhoneIcon class="phone-icon" size="20" />
              <div style="font-weight: 500">{{ slotProps.data.calledProgress }}</div>
              <div class="progress__bar" :style="{ width: `${barLength}px` }">
                <div
                  class="progress__bar--filled"
                  :style="{
                    width: `${
                      (slotProps.data.called_prospects * barLength) /
                        slotProps.data.total_prospects || 0
                    }px`,
                    'border-radius':
                      slotProps.data.called_prospects === slotProps.data.total_prospects
                        ? '8px'
                        : '8px 0px 0px 8px'
                  }"
                ></div>
              </div>
            </div>
          </template>
        </Column>
        <Column
          field="session_start_date"
          header="Start date"
          headerClass="data-table-header"
          bodyClass="cell"
          style="min-width: 195px"
        >
          <template #body="bodyProps">
            {{ formatDate(bodyProps.data.session_start_date) }}
          </template>
        </Column>
        <Column
          field="session_end_date"
          header="End date"
          headerClass="data-table-header"
          bodyClass="cell"
          style="min-width: 195px"
        >
          <template #body="bodyProps">
            {{ bodyProps.data.session_end_date ? formatDate(bodyProps.data.session_end_date) : '' }}
          </template>
        </Column>
        <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" class="prime-menu">
          <template #itemicon>
            <TrashIcon class="trash-icon" size="20" />
          </template>
        </Menu>
      </DataTable>
    </div>
    <div v-else class="table__row-group--empty">
      <Loader></Loader>
    </div>
  </div>
</template>

<script setup>
import { formatDate } from './helpers/date'
import { onMounted, ref } from 'vue'
import ExternalLinkIcon from './components/icons/ExternalLinkIcon.vue'
import ThreeDotIcon from './components/icons/ThreeDotIcon.vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Menu from 'primevue/menu'
import TrashIcon from './components/icons/TrashIcon.vue'
import PhoneIcon from './components/icons/PhoneIcon.vue'
import Loader from './components/Loader.vue'
import axios from './services/axiosService'
import router from './router'
import { useSessionStore } from './stores/sessionStore'

const sessionStore = useSessionStore()

const sessions = ref()

const barLength = 140 // in px

const menu = ref()
const items = ref([
  {
    label: 'Delete',
    command: deleteContactFromList
  }
])

function openSessionDetails(row) {
  // if (!row.originalEvent.target.matches('.cell') || row.originalEvent.target.offsetLeft === 0) {
  //   // used to make the first column not clickable
  //   return
  // }

  router.push({ name: 'session-details', params: { sessionId: row.data.session_id } })
}

function rowMenuClicked($events, row) {
  selectedRows.value = row.data
  menu.value.toggle($events)
}

const selectedRows = ref([])

const emit = defineEmits(['deleteContact'])

function deleteContactFromList() {
  emit('deleteContact', selectedRows.value)
}

onMounted(async () => {
  try {
    const response = await axios.get('/api/sessions')
    sessions.value = response.data.sessions
    sessionStore.setSessions(response.data.sessions)
  } catch (error) {
    console.error('Failed to fetch sessions:', error)
  }
})
</script>

<style lang="scss">
.trash-icon {
  stroke: #334155;
  margin-right: 4px;
  margin-left: 4px;
}

.session-list-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
  align-items: start;

  border: 1px solid #eaecf0;
  border-radius: 8px;

  min-height: 0;
  flex: 1 1 auto;

  .empty-message {
    color: #667085;
    font-size: 14px;
    vertical-align: middle;
  }

  .prime-table {
    min-height: 0;
    flex: 1 1 auto;
    width: 100%;
    border-radius: 8px;
  }

  .table__row-group--empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }

  .progress-cell {
    display: flex;
    align-items: center;
  }
  .progress__bar {
    margin-left: 16px;
    border-radius: 8px;
    background: #f2f2f2;
    height: 8px;
  }
  .progress__bar--filled {
    height: 8px;
    background: #313131;
  }

  .phone-icon {
    margin-right: 7px;
    stroke: #b8b8b8;
  }

  .cell-first-column {
    &:hover {
      cursor: pointer;
    }
  }
  .p-datatable-table-container {
    border-radius: 8px;
  }
}
</style>
