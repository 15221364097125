// src/socket.js
import { io } from 'socket.io-client'
import { refreshToken } from './axiosService'

export const socket = io(import.meta.env.VITE_BASE_URL, {
  withCredentials: true
})

socket.io.on('reconnection_attempt', () => {
  console.log('SOCKET reconnection_attempt socket')
})

socket.io.on('reconnect', () => {
  console.log('SOCKET reconnect socket')
})

socket.on('connect_error', async (error) => {
  console.error('Connection error:', error.message)
  try {
    if (error.message === 'auth_verification_failed') {
      await refreshToken()
      socket.connect()
    }
  } catch (err) {
    console.error('Error refreshing token:', err)
  }
})

socket.on('disconnect', (reason) => {
  console.log('SOCKET disconnect', reason)
})
socket.on('connect', (reason) => {
  console.log('SOCKET connect')
})
