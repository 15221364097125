<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33334 5.14706H16.6667M8.33334 13.9706V8.67647M11.6667 13.9706V8.67647M13.3333 17.5H6.66668C5.7462 17.5 5.00001 16.7099 5.00001 15.7353V6.02941C5.00001 5.5421 5.37311 5.14706 5.83334 5.14706H14.1667C14.6269 5.14706 15 5.5421 15 6.02941V15.7353C15 16.7099 14.2538 17.5 13.3333 17.5ZM8.33334 5.14706H11.6667C12.1269 5.14706 12.5 4.75202 12.5 4.26471V3.38235C12.5 2.89504 12.1269 2.5 11.6667 2.5H8.33334C7.87311 2.5 7.50001 2.89504 7.50001 3.38235V4.26471C7.50001 4.75202 7.87311 5.14706 8.33334 5.14706Z"
      :stroke="color"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#344054' // Default color
    },
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
