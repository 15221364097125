export function waitUntil(condition, interval = 100) {
  return new Promise((resolve) => {
    const checkCondition = () => {
      if (condition()) {
        resolve()
      } else {
        setTimeout(checkCondition, interval)
      }
    }
    checkCondition()
  })
}
