<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2072 5.79299L14.2072 8.79299M3.70723 16.293L7.34555 15.5599C7.5387 15.521 7.71604 15.4259 7.85533 15.2865L16.0001 7.13731C16.3906 6.7466 16.3903 6.11327 15.9995 5.72289L14.2741 3.99949C13.8835 3.60927 13.2504 3.60954 12.8601 4.00009L4.71453 12.1501C4.57552 12.2892 4.4806 12.4662 4.44165 12.659L3.70723 16.293Z"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
