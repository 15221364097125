<template>
  <transition name="fade" appear>
    <div v-if="isModalOpen" class="modal__overlay" @click.self="closeModal">
      <div class="modal" :style="{ width: `${width}px` }">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script setup>
const emit = defineEmits(['closeModal'])

const props = defineProps({
  isModalOpen: {
    type: Boolean,
    required: true
  },
  width: {
    type: Number,
    default: 650
  }
})

function closeModal() {
  emit('closeModal')
}
</script>

<style scoped>
.modal__overlay {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(15, 15, 15, 0.6);
}

.fade-enter-active,
.fade-leave-active {
  /* transition: opacity 0.27s ease; */
}

.fade-enter-from,
.fade-leave-to {
  /* opacity: 0; */
}

.modal {
  margin-top: 30px;
  background-color: #fff;
  height: calc(-60px + 100vh);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow:
    rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 5px 10px,
    rgba(15, 15, 15, 0.2) 0px 15px 40px;
}
</style>
