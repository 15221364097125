<template>
  <div class="call-logs__page">
    <div class="call-logs__title-container">
      <div class="page__title">Call history</div>
      <div class="log__result" v-if="!loading">
        {{ resultText }}
      </div>
    </div>
    <div class="page__body">
      <div class="body__header">
        <div class="log__filter">
          <SearchIcon class="search-icon" size="16" />
          <input
            autofocus
            type="text"
            class="filter-input"
            v-model="search"
            placeholder="Search by name or phone number..."
            @input="debouncedFetchCallLogs"
            ref="searchInput"
          />
        </div>
        <MultiSelect
          v-model="selectedUsers"
          placeholder="Select users"
          :options="userOptions"
          optionLabel="label"
          optionValue="value"
          @update:model-value="onUserFilterChange"
        />
        <analytics-date-range-selector
          @update:value="updateDateValue"
          initial-value="this_month_so_far"
        />
        <MultiSelect
          v-model="selectedStatuses"
          placeholder="Select call status"
          :options="callStatusOptions"
          optionLabel="label"
          optionValue="value"
          @update:model-value="onStatusChange"
        />
        <dropdown
          class="duration-dropdown"
          :items="durationOptions"
          :initial-value="durationOptions[0]"
          @update:value="onDurationChange"
        >
          <template #inputText="{ label }">
            <SuperiorIcon size="10" class="superior-icon" />
            {{ label }}
          </template>
          <template #item="{ label }">
            <SuperiorIcon size="10" class="superior-icon" />
            {{ label }}
          </template>
        </dropdown>
      </div>
      <call-logs-table
        :loading="loading"
        :call-logs="callLogs"
        :current-page="currentPage"
        :page-size="pageSize"
        :total-records="totalRecords"
        @update:page="updatePage"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import axios from './services/axiosService'
import SearchIcon from './components/icons/SearchIcon.vue'
import { debounce } from 'lodash'
import Dropdown from './components/Dropdown.vue'
import MultiSelect from './components/Multiselect.vue'
import SuperiorIcon from './components/icons/SuperiorIcon.vue'
import AnalyticsDateRangeSelector from './components/AnalyticsDateRangeSelector.vue'
import CallLogsTable from './components/CallLogsTable.vue'
import { useAuthStore } from './stores/auth'

const selectedUsers = ref([])
const userOptions = ref([])

const durationOptions = [
  {
    id: 0,
    label: '0 seconds'
  },
  {
    id: 30,
    label: '30 seconds'
  },
  {
    id: 60,
    label: '60 seconds'
  },
  {
    id: 90,
    label: '90 seconds'
  },
  {
    id: 120,
    label: '120 seconds'
  }
]

const selectedDuration = ref(durationOptions[0])

function onDurationChange(option) {
  selectedDuration.value = option
  currentPage.value = 1
  debouncedFetchCallLogs()
}

const resultText = computed(() => {
  if (totalRecords.value > 0) {
    return `${totalRecords.value} results`
  }
  if (totalRecords.value === 1) {
    return `${totalRecords.value} result`
  }
  return ''
})

const selectedStatuses = ref([])
const callStatusOptions = ref([
  { label: 'Meeting booked', value: 'meeting_booked' },
  { label: 'Connected', value: 'connected' },
  { label: 'Voicemail', value: 'voicemail' },
  { label: 'Canceled', value: 'canceled' },
  { label: 'Busy', value: 'busy' },
  { label: 'Failed', value: 'failed' },
  { label: 'No answer', value: 'no-answer' },
  { label: 'Left voicemail', value: 'left-voicemail' }
])

const callLogs = ref([])
const search = ref('')

const totalRecords = ref(0)
const currentPage = ref(1)
const pageSize = ref(50)

const startDate = ref(null)
const endDate = ref(null)

const onStatusChange = () => {
  currentPage.value = 1
  debouncedFetchCallLogs()
}

function updateDateValue(updatedDate) {
  startDate.value = updatedDate.startDate
  endDate.value = updatedDate.endDate
  currentPage.value = 1
  debouncedFetchCallLogs()
}

function updatePage(args) {
  currentPage.value = args.currentPage
  pageSize.value = args.pageSize
  debouncedFetchCallLogs()
}

const loading = ref(true)

const authStore = useAuthStore()

const onUserFilterChange = () => {
  currentPage.value = 1
  debouncedFetchCallLogs()
}

let firstTimeLoad = true

// Debounced version of fetchCallLogs with lodash
const debouncedFetchCallLogs = debounce(async () => {
  try {
    const statuses = selectedStatuses.value.map((status) => status.value)
    loading.value = true
    const minDuration = selectedDuration.value.id
    const response = await axios.post(`/api/call-logs`, {
      page: currentPage.value,
      limit: pageSize.value,
      search: search.value.trim(),
      statuses,
      startDate: startDate.value,
      endDate: endDate.value,
      minDuration,
      userIds: firstTimeLoad ? [authStore.userId] : selectedUsers.value.map((i) => i.value)
    })

    userOptions.value = response.data.allCompanyUsers
    if (firstTimeLoad) {
      selectedUsers.value = [userOptions.value.find((user) => user.value === authStore.userId)]
      firstTimeLoad = false
    }

    callLogs.value = response.data.calls
    totalRecords.value = response.data.totalRecords
    currentPage.value = response.data.currentPage
    pageSize.value = response.data.pageSize
  } catch (error) {
    console.error('Failed to fetch call logs:', error)
  } finally {
    loading.value = false
  }
}, 300) // 300 milliseconds
</script>

<style lang="scss">
.call-logs__page {
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1 1 auto;
  width: 100%;

  .call-logs__title-container {
    display: flex;
    align-items: baseline;
    margin-bottom: 24px;
  }

  ::placeholder {
    opacity: 1; /* Firefox */
  }

  .superior-icon {
    fill: #344054;
  }

  .log__result {
    margin-left: 12px;
    font-size: 14px;
    color: #667085;
    font-weight: 500;
  }
  .page__title {
    font-size: 20px;
    font-weight: 600;
    color: #344054;
  }
  .page__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 0;
    flex: 1 1 auto;
  }
  .body__header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
    gap: 16px;
    flex-wrap: wrap;
    .log__result {
      margin-left: 12px;
      font-size: 14px;
      color: #667085;
      font-weight: 500;
    }
    .log__filter {
      display: flex;

      position: relative;

      .filter-input {
        display: flex;
        padding: 8px 10px 8px 34px;
        box-sizing: border-box;
        width: 300px;

        font-size: 14px;
        background-color: white;
        transition: border-color 0.3s;

        border-radius: 8px;
        border: 1px solid #dbdbdb;
        color: #474747;
        font-weight: 500;

        &:focus {
          border: 1px solid #0070ff;
          outline: 2px solid #cbe2ff;
        }

        &::placeholder {
          color: #b9b9b9;
        }
      }
      .search-icon {
        position: absolute;
        top: 50%;
        margin-top: -0.5rem;
        margin-left: 8px;
        stroke: #828282;
      }
    }
  }
}
</style>
