<template>
  <div>
    <h2>Call Logs</h2>
    <DataTable
      :value="callLogs"
      tableStyle="min-width: 50rem"
      v-model:filters="filters"
      filterDisplay="row"
      ref="dt"
    >
      <template #header>
        <div style="text-align: left">
          <Button label="Export" @click="exportCSV($event)" />
        </div>
      </template>
      <Column field="date_updated" header="Date"></Column>
      <Column field="sid" header="Sid"></Column>
      <Column field="duration" header="Duration"></Column>
      <Column
        field="from_formatted"
        filterField="from_formatted"
        :showFilterMenu="false"
        :filterMenuStyle="{ width: '14rem' }"
        header="From"
      >
        <template #filter="{ filterModel, filterCallback }">
          <MultiSelect
            v-model="filterModel.value"
            @change="filterCallback()"
            :options="uniqueFromFormattedValues"
            placeholder="Any"
            style="min-width: 14rem"
            :maxSelectedLabels="1"
          >
            <template #option="slotProps">
              <div class="">
                <span>{{ slotProps.option }}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="to_formatted" header="To">
        <template #filter="{ filterModel, filterCallback }">
          <MultiSelect
            v-model="filterModel.value"
            @change="filterCallback()"
            :options="uniqueToFormattedValues"
            placeholder="Any"
            style="min-width: 14rem"
            :maxSelectedLabels="1"
          >
            <template #option="slotProps">
              <div class="">
                <span>{{ slotProps.option }}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="answered_by" header="Answered by"></Column>
      <Column field="machine_detection_duration" header="AMD duration"></Column>
      <Column header="Recording">
        <template #body="bodyProps">
          <audio controls :src="bodyProps.data.recording_url">
            Your browser does not support the audio element.
          </audio>
        </template>
      </Column>
    </DataTable>
    <p v-if="callLogs.length === 0">Loading call logs or no call logs available.</p>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue'
import axios from './services/axiosService'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import MultiSelect from 'primevue/multiselect'
import Button from 'primevue/button'
import { FilterMatchMode } from '@primevue/core/api'

const callLogs = ref([])

onMounted(async () => {
  try {
    const response = await axios.get('http://localhost:3000/api/back-office/calls', {
      withCredentials: true
    })
    callLogs.value = response.data
  } catch (error) {
    console.error('Failed to fetch call logs:', error)
  }
})

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  from_formatted: { value: null, matchMode: FilterMatchMode.IN },
  to_formatted: { value: null, matchMode: FilterMatchMode.IN },
  status: { value: null, matchMode: FilterMatchMode.EQUALS },
  verified: { value: null, matchMode: FilterMatchMode.EQUALS }
})

// Computed property to get unique 'from_formatted' values
const uniqueFromFormattedValues = computed(() => {
  const allValues = callLogs.value.map((log) => log.from_formatted)
  return [...new Set(allValues)] // Use Set to remove duplicates
})

const uniqueToFormattedValues = computed(() => {
  const allValues = callLogs.value.map((log) => log.to_formatted)
  return [...new Set(allValues)] // Use Set to remove duplicates
})
const dt = ref()
const exportCSV = () => {
  dt.value.exportCSV()
}
</script>

<style scope lang="scss"></style>
