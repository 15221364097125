<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.58676 3.5H13.1001M5.58676 7.28H13.1001M5.58676 11.06H13.1001M2.6001 3.5V3.50747M2.6001 7.28V7.28747M2.6001 11.06V11.0675"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 15 // Default size
    }
  }
}
</script>

<style scoped>
svg {
  stroke: #2b2c2e;
}
</style>
