<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.90625 5.62508L9.46637 9.61696C9.78741 9.84745 10.2126 9.84745 10.5336 9.61696L16.0938 5.62508M4.375 15.8334H15.625C16.6605 15.8334 17.5 14.9629 17.5 13.889V6.11119C17.5 5.03731 16.6605 4.16675 15.625 4.16675H4.375C3.33947 4.16675 2.5 5.03731 2.5 6.11119V13.889C2.5 14.9629 3.33947 15.8334 4.375 15.8334Z"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
