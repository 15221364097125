import { sortByDescendingDate } from "@/helpers/date"
import axiosInstance from "./axiosService"

export async function fetchNotes(contact) {
  console.log('fetchNotes')
  contact.noteLoading = true
  try {
    const { data } = await axiosInstance.get(`/api/prospects/${contact.id}/notes`)

    contact.notes = sortByDescendingDate(data.notes)
  } catch (error) {
    console.error('Error fetching notes:', error)
    // Handle error, e.g., show an error message to the user
  } finally {
    contact.noteLoading = false
  }
}
