import axiosInstance from './axiosService'

export async function deleteNote(contact, noteId) {
  await axiosInstance.delete(`/api/prospects/${contact.id}/notes/${noteId}`)

  const index = contact.notes.findIndex((note) => note.id === noteId)
  if (index !== -1) {
    contact.notes.splice(index, 1)
  }

  console.log('Note deleted')
}
