<template>
  <div class="p-fluid">
    <h2>New Invitation</h2>

    <!-- Email Input -->
    <div class="p-field">
      <label for="email">Email</label>
      <InputText
        id="email"
        v-model="formData.email"
        placeholder="Enter email"
        :class="{ 'p-invalid': !isEmailValid && formSubmitted }"
      />
      <small v-if="!isEmailValid && formSubmitted" class="p-error">Invalid email format.</small>
    </div>

    <!-- Company Dropdown -->
    <div class="p-field">
      <label for="company">Company</label>
      <Dropdown
        id="company"
        v-model="formData.companyId"
        :options="companyOptions"
        optionLabel="name"
        optionValue="company_id"
        placeholder="Select a company"
        :class="{ 'p-invalid': !formData.companyId && formSubmitted }"
      />
      <small v-if="!formData.companyId && formSubmitted" class="p-error"
        >Company is required.</small
      >
    </div>

    <!-- Submit Button -->
    <div class="submit-button">
      <Button label="Create Invitation" @click="submitForm" />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import axiosInstance from '../../services/axiosService'

const props = defineProps({
  companyOptions: {
    type: Array,
    required: true
  }
})

const formData = ref({
  email: '',
  companyId: null
})
const formSubmitted = ref(false)

const isEmailValid = ref(true)

const emit = defineEmits('invitationCreated')

const submitForm = async () => {
  formSubmitted.value = true

  // Basic email validation
  isEmailValid.value = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.value.email)

  if (!isEmailValid.value || !formData.value.companyId) {
    return // Prevent submission if validation fails
  }

  try {
    const response = await axiosInstance.post('/api/invitations/create-invitation', {
      email: formData.value.email,
      companyId: formData.value.companyId
    })
    console.log('Invitation created successfully:', response.data)
    // Reset form
    formSubmitted.value = false
    formData.value.email = ''
    formData.value.companyId = null

    // Emit an event to notify the parent that an invitation was created
    emit('invitationCreated', response.data.invitation)
  } catch (error) {
    console.error('Error creating invitation:', error)
  }
}
</script>

<style scoped lang="scss">
h2 {
  margin-top: 0;
}
.p-field {
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.submit-button {
  display: flex;
  flex-direction: column;
}
</style>
