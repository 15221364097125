<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9351 6.67825C15.7768 4.67866 13.6119 3.33334 11.1323 3.33334C8.32094 3.33334 5.91405 5.0628 4.92028 7.51448M14.9005 7.51448H18.25V4.16957M5.39823 13.3681C6.55651 15.3677 8.72144 16.713 11.201 16.713C14.0124 16.713 16.4193 14.9835 17.4131 12.5318M7.43283 12.5318H4.08333V15.8767"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
