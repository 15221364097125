<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.54706 17.5V9.35333C7.54706 8.80105 7.99477 8.35333 8.54706 8.35333H11.5956C12.1479 8.35333 12.5956 8.80105 12.5956 9.35333V17.5M7.54706 17.5L7.54821 14.067C7.54839 13.5146 7.10062 13.0667 6.54821 13.0667H3.5C2.94772 13.0667 2.5 13.5144 2.5 14.0667V16.5C2.5 17.0523 2.94772 17.5 3.5 17.5H7.54706ZM7.54706 17.5H12.5956M12.5956 17.5V3.5C12.5956 2.94772 13.0433 2.5 13.5956 2.5H16.5C17.0523 2.5 17.5 2.94772 17.5 3.5V16.5C17.5 17.0523 17.0523 17.5 16.5 17.5H12.5956Z"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
