<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.736437 13.9289C0.937415 14.3737 1.37525 14.6367 1.83317 14.6367C1.99876 14.6367 2.16715 14.6023 2.32747 14.5299L16.9633 7.91357C17.4059 7.71358 17.6847 7.26768 17.6706 6.78243C17.6566 6.29719 17.3519 5.86824 16.8989 5.69459L2.26295 0.0805426C1.64141 -0.158458 0.946961 0.152638 0.709113 0.773019C0.470935 1.3934 0.781208 2.089 1.40159 2.32685L13.3467 6.90835L1.33707 12.3374C0.731499 12.611 0.462541 13.3235 0.736437 13.9289Z"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
      vector-effect="non-scaling-stroke"
    />
    <path
      d="M17.5652 11.587C17.293 10.9811 16.5809 10.7115 15.9745 10.9833L1.03812 17.6998C0.432219 17.9724 0.161615 18.6843 0.434359 19.2905C0.634843 19.7364 1.0735 20.0003 1.53225 20.0003C1.69701 20.0003 1.86474 19.9662 2.02506 19.8939L16.9618 13.1772C17.5672 12.9051 17.8379 12.1929 17.5652 11.587Z"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
