import axiosInstance from './axiosService'

export async function updateCallDisposition(contact, logId, callDisposition) {
  const result = await axiosInstance.post(`/api/prospects/${contact.id}/update-call-disposition`, {
    logId,
    callDisposition
  })
  if (contact.callLogs.find((log) => log.id === logId)) {
    contact.callLogs.find((log) => log.id === logId).callDisposition = callDisposition
  }
  const newDialStatus = result.data.newDialStatus
  if (newDialStatus) {
    contact.dialStatus = newDialStatus
    if (newDialStatus !== 'connected') {
      if (contact.callLogs.find((log) => log.id === logId)) {
        delete contact.callLogs.find((log) => log.id === logId).callSid
      }
    }
  }
  console.log('Call disposition updated')
}
