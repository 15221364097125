<template>
  <div class="message-notification">
    <div class="text">{{ text }}</div>
  </div>
</template>

<script setup>
import { usePhoneStore } from '@/stores/phone'

const props = defineProps({
  text: {
    type: String,
    required: true
  }
})

const phoneStore = usePhoneStore()

setTimeout(() => {
  phoneStore.successMessage = ''
}, 3000)
</script>

<style scoped>
.message-notification {
  position: absolute;
  bottom: 30px;
  color: #fff;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 16px 24px;
  border-radius: 8px;
  background: rgba(20, 20, 20, 0.59);
  display: flex;
  align-items: center;
  line-height: 14px;
  font-size: 14px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 9;
}
</style>
