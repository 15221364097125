import axios from './axiosService'

export async function fetchUserVoicemails() {
  try {
    const { data } = await axios.get(`/api/voicemails`)
    return { files: data.audioFiles, expiresIn: data.expiresIn }
  } catch (error) {
    console.error('Error fetching voicemails', error)
    throw error
  }
}

export async function deleteVoicemail(id) {
  try {
    return await axios.delete(`/api/voicemails/${id}`)
  } catch (error) {
    console.error('Error deleting voicemail', error)
    throw error
  }
}

export async function saveVoicemail(recording) {
  try {
    return await axios.post(`/api/voicemails/`, recording)
  } catch (error) {
    console.error('Error saving voicemail', error)
    throw error
  }
}
