<template>
  <div class="dropdown" ref="dropdown">
    <div @click="toggleDropdown" class="dropdown__button">
      <div class="dropdown__buttonText">{{ dropdownTitle }}</div>
      <ChevronDownIcon class="dropdown__button-svg" v-if="!isOpen" color="#D0D5DD" size="12" />
      <ChevronUpIcon class="dropdown__button-svg" v-else color="#D0D5DD" size="12" />
    </div>
    <div
      v-if="isOpen"
      class="dropdown--open"
      @click.stop=""
      :style="{
        'min-width': props.minWidth ? `${props.minWidth}px` : '',
        right: props.alignRight ? 0 : '',
        left: !props.alignRight ? 0 : ''
      }"
    >
      <div class="dropdown__main">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import ChevronDownIcon from './icons/ChevronDownIcon.vue'
import ChevronUpIcon from './icons/ChevronUpIcon.vue'
import { onClickOutside } from '@vueuse/core'

const props = defineProps({
  minWidth: {
    type: Number
  },
  alignRight: {
    type: Boolean,
    default: false
  },
  dropdownTitle: {
    type: String,
    required: true
  }
})

const isOpen = ref(false)

function toggleDropdown() {
  if (props.disable) {
    return
  }
  isOpen.value = !isOpen.value
}

const dropdown = ref(null)

function closeDropdown() {
  isOpen.value = false
}

onClickOutside(dropdown, () => {
  closeDropdown()
})
</script>

<style lang="scss" scoped>
.dropdown {
  display: flex;
  user-select: none;
  position: relative;
  background: #fff;
  color: #344054;
  align-self: flex-start;

  .dropdown__button {
    padding: 6px 12px;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: opacity 0.4s ease;

    &:hover {
      border: 1px solid #c2c2c2;
      cursor: pointer;
    }

    .dropdown__buttonText {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 14px;
      margin-right: 8px;
    }

    .dropdown__button-svg {
      pointer-events: none;
    }
  }

  .dropdown--open {
    position: absolute;
    top: calc(100% + 2px);
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #344054;
    font-size: 14px;
    display: inline-block;
    font-weight: 500;
    overflow: visible;
    background: #fff;
    z-index: 2;
  }
}
</style>
