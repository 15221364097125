import axiosInstance from './axiosService'

export async function updateCustomField(contact, { field, value }) {
  await axiosInstance.post(`/api/prospects/${contact.id}/update-custom-field`, {
    field,
    value
  })
  contact[field] = value
  console.log('Custom field updated')
}
