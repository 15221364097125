<template>
  <div class="snooze-contact">
    <empty-dropdown alignRight dropdownTitle="Snooze" v-if="!snoozeUntilCopy && !doNotCallCopy">
      <div class="snooze-dropdown">
        <div class="snooze-section">
          <div class="snooze-title">Snooze calls to this contact until:</div>
          <div class="snooze-selector">
            <date-selector
              @update:value="updateSnoozeValue"
              :initial-value="initialSnoozeOption"
              default-option-label="3-business-days"
              :option-labels="optionLabels"
              columnLayout
            />

            <div class="snooze-selector__button" @click="snooze(snoozeDate)">
              <AlarmIcon size="20" style="stroke: #fff" />
              Snooze
            </div>
          </div>
        </div>
        <div class="do-not-call-section">
          <div class="do-not-call__button-container">
            <div class="do-not-call__button" @click="updateDoNotCall(true)">
              <NoPhoneIcon size="20" style="stroke: #ff3e32" />
              Do not call this contact
            </div>
          </div>
        </div>
      </div>
    </empty-dropdown>
    <div v-else-if="snoozeUntilCopy" class="remove-snooze__button" @click="snooze(null)">
      Remove snooze
    </div>
    <div v-else-if="doNotCallCopy" class="resume-calls__button" @click="updateDoNotCall(false)">
      Enable calls
    </div>
  </div>
</template>

<script setup>
import NoPhoneIcon from './icons/NoPhoneIcon.vue'
import AlarmIcon from './icons/AlarmIcon.vue'
import EmptyDropdown from './EmptyDropdown.vue'
import DateSelector from './DateSelector.vue'
import { ref, watch, computed, onMounted } from 'vue'
import { formatDateWithoutTime } from '@/helpers/date'
import axiosInstance from '@/services/axiosService'
import { addBusinessDays, addMonths, addWeeks, format } from 'date-fns'
import { usePhoneStore } from '@/stores/phone'

const props = defineProps({
  snoozeUntil: {
    type: [String, null],
    required: true
  },
  doNotCall: {
    type: Boolean,
    required: true
  },
  contactId: {
    type: Number,
    required: true
  }
})

const optionLabels = computed(() => {
  const today = new Date()
  return {
    '3-business-days': `In 3 business days (${format(addBusinessDays(today, 3), 'EEEE')})`,
    '1-week': `In 1 week (${format(addWeeks(today, 1), 'MMMM d')})`,
    '2-weeks': `In 2 weeks (${format(addWeeks(today, 2), 'MMMM d')})`,
    '1-month': `In 1 month (${format(addMonths(today, 1), 'MMMM d')})`,
    '3-month': `In 3 months (${format(addMonths(today, 3), 'MMMM d')})`,
    '6-month': `In 6 months (${format(addMonths(today, 6), 'MMMM d')})`,
    custom: 'Custom date'
  }
})

const snoozeDate = ref()
const snoozeUntilCopy = ref()
const doNotCallCopy = ref()
let initialSnoozeOption

function initSnoozeOption() {
  const today = new Date()
  initialSnoozeOption = addBusinessDays(today, 3)
  snoozeDate.value = initialSnoozeOption

  snoozeUntilCopy.value =
    new Date(props.snoozeUntil) > new Date() ? new Date(props.snoozeUntil) : null

  doNotCallCopy.value = props.doNotCall
}

onMounted(() => {
  initSnoozeOption()
})

watch(
  () => props.contactId,
  (newValue) => {
    if (newValue) {
      initSnoozeOption(newValue)
    }
  }
)

function updateSnoozeValue(date) {
  snoozeDate.value = date
}

const emit = defineEmits(['snoozed', 'update:doNotCall'])

async function snooze(date) {
  try {
    let formattedDate
    const optionsNoTime = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }
    if (date) {
      formattedDate = new Date(date).toISOString().split('T')[0]
    } else {
      formattedDate = null
    }

    await axiosInstance.put(`/api/prospects/${props.contactId}/snooze`, {
      snoozeUntil: formattedDate
    })
    snoozeUntilCopy.value = formattedDate
    emit('snoozed', formattedDate)
  } catch (error) {
    console.log('error while snoozing', error)
  }
}

async function updateDoNotCall(value) {
  try {
    await axiosInstance.put(`/api/prospects/${props.contactId}/do-not-call`, {
      doNotCall: value
    })
    doNotCallCopy.value = value
    emit('update:doNotCall', value)
  } catch (error) {
    console.log('error while updating do not call', error)
  }
}
</script>

<style scoped lang="scss">
.snooze-contact {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
  .snooze-until {
    font-weight: 500;
  }
  .snooze-dropdown {
    text-wrap: nowrap;
    display: flex;
    flex-direction: column;
    width: 310px;
    .snooze-section {
      padding: 16px;
      display: flex;
      gap: 4px;
      flex-direction: column;

      .snooze-selector {
        display: flex;
        gap: 8px;
        flex-direction: column;

        .snooze-selector__button {
          padding: 8px 16px;
          font-weight: 500;
          border-radius: 8px;
          display: flex;
          align-items: center;
          background: #006fe6;
          cursor: pointer;
          color: #fff;
          gap: 7px;
          align-self: start;
        }
      }
    }

    .do-not-call-section {
      border-top: 1px solid #d0d5dd;
      .do-not-call__button-container {
        padding: 16px;
        display: flex;
        .do-not-call__button {
          padding: 8px 16px;
          font-weight: 500;
          border-radius: 8px;
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #ff3e32;
          border: 1px solid #dbdbdb;
          gap: 7px;
        }
      }
    }
  }

  .remove-snooze__button {
    padding: 6px 16px;
    font-weight: 500;
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #ff3e32;
    border: 1px solid #dbdbdb;
    gap: 7px;
  }

  .resume-calls__button {
    padding: 6px 16px;
    font-weight: 500;
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #0070ff;
    border: 1px solid #dbdbdb;
    background: #fff;
    gap: 7px;
  }
}
</style>
