<template>
  <div>
    <div v-if="newTaskEditorOpen === false" class="add-task__button-section">
      <div class="add-task__button" @click="newTaskEditorOpen = true">
        <div class="plus-icon">
          <PlusIcon color="#828282" size="20" />
        </div>
        <div class="add-task__button-text">Add a new task</div>
      </div>
    </div>
    <div v-else class="add-task__editor-section">
      <div class="header">New task</div>
      <div class="editor-item">
        <input
          class="editor-item__input"
          :class="{ invalid: isInputInvalid }"
          placeholder="Task title"
          v-model="newTaskTitle"
        />
      </div>
      <div class="editor-item">
        <div class="editor-item__title">Due date</div>
        <date-selector
          @update:value="updateNewDateValue"
          :initial-value="initialNewDueDate"
          :option-labels="optionLabels"
        />
      </div>
      <div class="editor-item" v-if="showTaskType">
        <div class="editor-item__title">Task type</div>
        <dropdown
          :items="contactStore.taskTypeList[importSource]"
          :initial-value="initialTaskType"
          :min-width="120"
          @update:value="updateNewTaskType"
        />
      </div>
      <div class="editor-item">
        <textarea
          class="editor-item__input"
          v-model="newTaskBody"
          rows="5"
          placeholder="Task body"
        ></textarea>
      </div>

      <div class="add-task__footer">
        <div class="add-task__save-button" @click="saveNewTask">Save new task</div>
        <div class="add-task__error-message">{{ errorMessage }}</div>
      </div>
    </div>
    <div class="task__section-body" v-if="pinnedTask">
      <prospect-task :task="pinnedTask" @updateTask="updateTask" :import-source="importSource" />
    </div>
    <div class="loader-section" v-if="loading">
      <Loader></Loader>
    </div>
    <div v-for="task in regularTasks" :key="task.id" class="task__section-body">
      <prospect-task :task="task" @updateTask="updateTask" :import-source="importSource" />
    </div>
  </div>
</template>

<script setup>
import Loader from './Loader.vue'
import PlusIcon from './icons/PlusIcon.vue'
import DateSelector from './DateSelector.vue'
import Dropdown from './Dropdown.vue'
import ProspectTask from './ProspectTask.vue'

import { ref, watch, computed } from 'vue'
import { useContactStore } from '../stores/contactStore'
import { addBusinessDays, addMonths, addWeeks, format } from 'date-fns'

const pinnedTask = computed(() => props.tasks.find((task) => task.pinned))
const regularTasks = computed(() => props.tasks.filter((task) => !task.pinned))

const optionLabels = computed(() => {
  const today = new Date()
  return {
    today: 'Today',
    tomorrow: 'Tomorrow',
    '2-business-days': `In 2 business days (${format(addBusinessDays(today, 2), 'EEEE')})`,
    '3-business-days': `In 3 business days (${format(addBusinessDays(today, 3), 'EEEE')})`,
    '1-week': `In 1 week (${format(addWeeks(today, 1), 'MMMM d')})`,
    '2-weeks': `In 2 weeks (${format(addWeeks(today, 2), 'MMMM d')})`,
    '1-month': `In 1 month (${format(addMonths(today, 1), 'MMMM d')})`,
    '3-month': `In 3 months (${format(addMonths(today, 3), 'MMMM d')})`,
    '6-month': `In 6 months (${format(addMonths(today, 6), 'MMMM d')})`,
    custom: 'Custom date'
  }
})

const props = defineProps({
  tasks: {
    type: Object,
    required: true
  },
  loading: {
    type: Boolean,
    required: true
  },
  importSource: {
    type: String,
    required: true
  }
})

const showTaskSources = ['hubspot', 'pipedrive']
const showTaskType = computed(() => showTaskSources.includes(props.importSource))

const newTaskEditorOpen = ref(false)

const contactStore = useContactStore()
const initialTaskType = contactStore.taskTypeList[props.importSource][0]
const initialNewDueDate = new Date()

function resetNewTask() {
  newTaskTitle.value = ''
  newTaskBody.value = ''
  newTaskDate.value = initialNewDueDate
  newTaskType.value = initialTaskType.id
}
const newTaskTitle = ref()
const newTaskBody = ref()
const newTaskDate = ref()
const newTaskType = ref()

resetNewTask()

function updateNewTaskType(type) {
  newTaskType.value = type.id
}

function updateNewDateValue(date) {
  newTaskDate.value = date
}

const emit = defineEmits(['saveNewTask', 'updateTask'])

const errorMessage = ref('')
const isInputInvalid = ref(false)

watch(
  () => newTaskTitle.value,
  (newValue) => {
    if (newValue) {
      isInputInvalid.value = false
      errorMessage.value = ''
    } else {
      isInputInvalid.value = true
    }
  }
)

function saveNewTask() {
  if (!newTaskTitle.value) {
    isInputInvalid.value = true
    errorMessage.value = 'Task title is mandatory'
    return
  }

  isInputInvalid.value = false

  emit('saveNewTask', {
    title: newTaskTitle.value,
    body: newTaskBody.value,
    dueDate: newTaskDate.value,
    type: newTaskType.value
  })
  newTaskEditorOpen.value = false
  resetNewTask()
}

function updateTask(taskId, taskField, taskValue) {
  emit('updateTask', taskId, taskField, taskValue)
}
</script>

<style scoped lang="scss">
.loader-section {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
.task__section-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.add-task__button-section {
  display: flex;
  margin-bottom: 16px;
  .add-task__button {
    padding: 4px 16px 4px 8px;
    transition: 0.05s ease;
    display: flex;
    &:hover {
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.07);
      opacity: 1;
      cursor: pointer;
    }

    .plus-icon {
      display: flex;
      align-items: center;
      margin-right: 5px;
    }

    .add-task__button-text {
      color: #828282;
    }
  }
}

.add-task__editor-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  .header {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
  }

  .add-task__footer {
    display: flex;
    align-items: center;
    .add-task__save-button {
      background-color: #0070ff;
      color: #fff;
      padding: 4px 8px;
      font-weight: 500;
      border-radius: 4px;
      font-size: 14px;
      display: flex;
      align-items: center;
      transition:
        background-color 0.4s ease,
        border-color 0.4s ease;

      align-self: flex-start;

      &:hover {
        cursor: pointer;
        background-color: #006fe6;
      }
    }

    .add-task__error-message {
      color: #f80000;
      margin-left: 12px;
    }
  }
}

.editor-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  .editor-item__title {
    font-size: 12px;
    font-weight: 500;
    color: #667085;
    margin-bottom: 4px;
  }
  .editor-item__input {
    line-height: 1.6;
    font-family:
      Inter,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif;

    resize: vertical;

    font-size: 14px;
    color: #667085;
    background: rgba(242, 241, 238, 0.6);
    border: 1px solid rgba(15, 15, 15, 0.1);
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    font-weight: 500;

    &:focus {
      border: 1px solid #0070ff;
      outline: 2px solid #cbe2ff;
    }
  }

  .invalid:focus {
    border: 1px solid #f80000;
    outline: 2px solid #fecdcd;
  }

  .invalid {
    border: 1px solid #f80000;
  }
}
</style>
