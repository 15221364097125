<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 11.4048C17.0561 12.1939 18 14.1806 18 15.4047C18 15.7861 17.7218 16.0953 17.3785 16.0953H17M13 8.17043C13.6832 7.77522 14.1429 7.03654 14.1429 6.1905C14.1429 5.34446 13.6832 4.60578 13 4.21057M2.62148 16.0953H13.569C13.9122 16.0953 14.1905 15.7861 14.1905 15.4047C14.1905 13.0076 12.1878 11.0643 8.09524 11.0643C4.00272 11.0643 2 13.0076 2 15.4047C2 15.7861 2.27824 16.0953 2.62148 16.0953ZM10.381 6.1905C10.381 7.45286 9.3576 8.47621 8.09524 8.47621C6.83287 8.47621 5.80952 7.45286 5.80952 6.1905C5.80952 4.92813 6.83287 3.90479 8.09524 3.90479C9.3576 3.90479 10.381 4.92813 10.381 6.1905Z"
      vector-effect="non-scaling-stroke"
      stroke-width="1.67"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 20 // Default size
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
